import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMemberships,
  putDefaultMembership,
  validateEmailForOrganization,
  postUser,
  getOrganization
} from 'api/userManagement';

export enum CompanySelectionDialogViews {
 SET_DEFAULT = 'set-default',
 CHANGE_COMPANY = 'change-company',
 INVITATION_ACCEPTED = 'invitation-accepted',
}

export const fetchMemberships = createAsyncThunk<MembershipsResponse, string>(
 'memberships/fetchMemberships',
 async (userId: number | string, { rejectWithValue }) => {
  try {
   const response = await getMemberships(userId.toString());
   userManagementSlice.actions.setMemberships(response);
   return response;
  } catch (error) {
   return rejectWithValue(error);
  }
 }
);

export const updateDefaultMembership = createAsyncThunk<any, { userId: number | string; membershipId: number | string }>(
 'memberships/updateDefaultMembership',
 async ({ userId, membershipId }, { rejectWithValue }) => {
  try {
   const response = await putDefaultMembership(userId.toString(), membershipId.toString());
   userManagementSlice.actions.setMemberships(membershipId);
   return response;
  } catch (error) {
   return rejectWithValue(error);
  }
 }
);

export const createUser = createAsyncThunk<any, any>('userManagement/createUser', async postUserRequest => {
  return await postUser(postUserRequest);
});

interface UserManagementState {
 memberships: MembershipsResponse | null;
 showCarrierSelectDialog: boolean;
 loading: boolean;
 error: string | null;
 updateStatus: 'FAILED' | 'SUCCESS';
 membershipDialogView:
 | CompanySelectionDialogViews.SET_DEFAULT
 | CompanySelectionDialogViews.CHANGE_COMPANY
 | CompanySelectionDialogViews.INVITATION_ACCEPTED;
 activeMembership: Membership | null;
}

const initialState: UserManagementState = {
 memberships: null,
 loading: false,
 error: null,
 updateStatus: null,
 showCarrierSelectDialog: false,
 membershipDialogView: CompanySelectionDialogViews.SET_DEFAULT,
 activeMembership: null,
};

const userManagementSlice = createSlice({
 name: 'user',
 initialState,
 reducers: {
  setMemberships: (state, { payload }) => ({ ...state, memberships: payload }),
  setActiveMembership: (state, { payload }) => ({ ...state, activeMembership: payload }),
  toggleMembershipDialog: state => {
   return {
    ...state,
    showCarrierSelectDialog: !state.showCarrierSelectDialog,
   };
  },
  setMembershipDialogView: (state, { payload }) => ({ ...state, membershipDialogView: payload }),
  setOrganization: (state, { payload }) => {
   return {
    ...state,
    organization: payload?.organization,
   };
  },
 },
 extraReducers: builder => {
  builder
   .addCase(fetchMemberships.pending, state => {
    state.loading = true;
    state.error = null;
   })
   .addCase(fetchMemberships.fulfilled, (state, action) => {
    state.memberships = action.payload as MembershipsResponse;
    state.loading = false;
   })
   .addCase(fetchMemberships.rejected, (state, action) => {
    state.error = action.payload as string;
    state.loading = false;
   })
   .addCase(updateDefaultMembership.pending, state => {
    state.loading = true;
    state.error = null;
    state.updateStatus = null;
   })
   .addCase(updateDefaultMembership.fulfilled, state => {
    state.loading = false;
    state.updateStatus = 'SUCCESS';
   })
   .addCase(updateDefaultMembership.rejected, (state, action) => {
    state.error = action.payload as string;
    state.loading = false;
    state.updateStatus = 'FAILED';
   });
   // Handle createUser
   builder.addCase(createUser.pending, state => {
     state.loading = true;
     state.error = undefined;
   });
   builder.addCase(createUser.fulfilled, state => {
     state.loading = false;
   });
   builder.addCase(createUser.rejected, (state, action) => {
     state.loading = false;
     state.error = action.error.message;
   });
 },
});

export const setMemberships = (memberships: MembershipsResponse) => dispatch => dispatch(userManagementSlice.actions.setMemberships(memberships));
export const toggleMembershipDialog = (show: boolean) => dispatch => dispatch(userManagementSlice.actions.toggleMembershipDialog());
export const setMembershipDialogView = (view: CompanySelectionDialogViews) => dispatch =>
 dispatch(userManagementSlice.actions.setMembershipDialogView(view));
export const setActiveMembership = (membership: Membership) => dispatch => dispatch(userManagementSlice.actions.setActiveMembership(membership));

export default userManagementSlice.reducer;
