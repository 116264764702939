import { BaseModel } from '../base.model';

export enum CashAdvanceSourceTypeV3 {
  LoadPay = 'LoadPay',
  TChek = 'TChek',
  EFSFuelCard = 'AdvantageCard'
}

export class CashAdvanceOptionsSourceV3 extends BaseModel {
  constructor(json?: CashAdvanceSourceV3JSON) {
    super(json);
  }

  readonly advanceSourceType: CashAdvanceSourceTypeV3;
  readonly isEnabled: boolean;
  readonly minimumFee: number;
  readonly percentFee: number;
}
