import { BaseModel } from '../base.model';
import { CashAdvanceOptionsSourceV3 } from './cash-advance-options-source-V3.model';
import { CashAdvanceOptionsSource } from './cash-advance-options-source.model';
import { CashAdvanceOptionsValidation } from './cash-advance-options-validation.model';

export class CashAdvanceOptions extends BaseModel {
  constructor(json?: CashAdvanceOptionsJSON) {
    super(json);

    if (json) {
      this.cashAdvanceSources = json.cashAdvanceSources.map(s => new CashAdvanceOptionsSource(s));
    }
  }

  availableBalance: number;
  sourceCurrencyCode: string;
  cashAdvanceSources: CashAdvanceOptionsSource[];
  advanceSourceTypes: CashAdvanceOptionsSourceV3[];
  carrierIsEnabled: boolean;
  carrierDisabledReason?: string;
  loadIsEnabled: boolean;
  loadDisabledReason?: string;
  promotionId?: number;
  validationErrors: CashAdvanceOptionsValidation[];
}
