export enum OrganizationTypes {
 CARRIER,
 TMC,
 VENDOR,
}

export enum NastRoles {
 OWNER_OPERATOR = 'Owner-Operator',
 MANAGER = 'Manager',
 DISPATCHER = 'Dispatcher',
 DRIVER = 'Driver',
 AR = 'AR',
}

export enum TMCRoles {
 TMC_DOMESTIC = 'TMCDomestic',
 TMC_DOCK_MANAGER = 'TMCDockMgr',
 TMC_GLOBAL = 'TMCGlobal',
 TMC_LIMITED_USER = 'TMCLimitedUser',
}

export enum VendorRoles {
 FACTORING_COMPANY = 'FactoringCompany',
}

export enum MembershipStatus {
 ACTIVE = 0,
 DISABLED = 1,
 PENDING = 2,
}
