import { Action, AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';

import * as a from '@features/security/auth.actions';
import { AuthRepository } from 'app/repositories/auth.repository';
import { LocalStorageRepository } from '@app/repositories/local-storage.repository';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { RequestFailureAPIErrorAction } from 'store/requests.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { User } from 'shared/models/user.model';
import { hazmatEpics } from '@features/security/auth/hazmat.epics';
import { insuranceEpics } from '@features/security/auth/insurance.epics';
import { completeAppInitialization } from 'store/epics/app.actions';
import { initCulture } from 'app/i18n/culture.actions';
import { termsAndConditionsEpics } from '@features/security/auth/terms-and-conditions.epics';
import { medalliaEpics } from '@features/security/auth/medallia.epics';

type LoginEpic<OutputAction extends Action> = NavCarrierEpic<
 OutputAction,
 {
  auth: AuthRepository;
  carriersRepo: CarriersRepository;
  localStorage: LocalStorageRepository;
 }
>;

export const storeCredentialsOnLoginEpic: LoginEpic<a.StoreCredentialsAction> = action$ =>
  action$.ofType<a.CompleteLoginAction>(a.COMPLETE_LOGIN)
    .map(({ userJSON, carrierDetail }) => a.storeCredentials(new User(userJSON), carrierDetail));

export const processPendingPostLoginActionsEpic: LoginEpic<AnyAction> = (action$, state$) =>
  action$.ofType<a.StoreCredentialsAction>(a.STORE_CREDENTIALS)
    .map(() => state$.value.auth.postLoginActionQueue)
    .mergeMap(pendingActions => ActionsObservable.from([...pendingActions, a.clearPostLoginQueue(), completeAppInitialization()]));

export const initializeAppWithNoUserEpic: LoginEpic<AnyAction> = (action$, state$, { localStorage }) =>
  action$
    .ofType(a.INIT_USER)
    .map(() => ({ accessToken: JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken }))
    .filter(({ accessToken }) => !Boolean(accessToken))
    .mergeMap(() => ActionsObservable.from([completeAppInitialization(), initCulture()]));

export const loginEpics = (action$, state$, { auth }) =>
 combineEpics(
  medalliaEpics,
  insuranceEpics,
  hazmatEpics,
  termsAndConditionsEpics,
  storeCredentialsOnLoginEpic,
  processPendingPostLoginActionsEpic,
  initializeAppWithNoUserEpic
 )(action$, state$, {
  auth,
  carriersRepo: Container.get(CarriersRepository),
  localStorage: new LocalStorageRepository(),
 });
