export enum SelectCarrierDialogViews {
 SET_DEFAULT = 'set-default',
 CHANGE_COMPANY = 'change-company',
}

export const basicMembershipState: MembershipState = {
 memberships: [
  {
   id: '6650c266f67f26f1a6858ae6',
   organizationId: '66057dcf153ec1fc5c238dca',
   userId: '6650c20af67f26f1a6858ae2',
   status: 0,
   companyCode: 'T600',
   roles: ['Manager'],
   permissions: ['AR', 'CHRTenders', 'Claims', 'FindLoads', 'LoadManagement'],
   isAdmin: true,
   isDefault: false,
   version: 0,
   attributes: {
    companyName: 'Blah Carrier',
    companyCode: null,
    firstName: 'Ian',
    lastName: 'Farris',
    email: 'Farrian@chrobinson.com',
    phone: '+1123456789',
   },
  },
  {
   id: '6650c20af67f26f1a6858ae3',
   organizationId: '660b28451a33f8126fa77496',
   userId: '6650c20af67f26f1a6858ae2',
   status: 0,
   companyCode: 'T126776',
   roles: ['Manager'],
   permissions: ['AR', 'CHRTenders', 'Claims', 'FindLoads', 'LoadManagement'],
   isAdmin: true,
   isDefault: true,
   version: 0,
   attributes: {
    companyName: 'rennmat org',
    companyCode: null,
    firstName: 'Ian',
    lastName: 'Farris',
    email: 'Farrian@chrobinson.com',
    phone: '+1123456789',
   },
  },
 ],
 membershipDialogView: SelectCarrierDialogViews.CHANGE_COMPANY,
 activeMembership: null,
};

export const singleMembershipState: MembershipState = {
 memberships: [
  {
   id: '6650c266f67f26f1a6858ae6',
   organizationId: '66057dcf153ec1fc5c238dca',
   userId: '6650c20af67f26f1a6858ae2',
   status: 0,
   companyCode: 'T600',
   roles: ['Manager'],
   permissions: ['AR', 'CHRTenders', 'Claims', 'FindLoads', 'LoadManagement'],
   isAdmin: false,
   isDefault: false,
   version: 0,
   attributes: {
    companyName: 'Blah Carrier',
    companyCode: null,
    firstName: 'Ian',
    lastName: 'Farris',
    email: 'Farrian@chrobinson.com',
    phone: '+1123456789',
   },
  },
 ],
 membershipDialogView: SelectCarrierDialogViews.SET_DEFAULT,
 activeMembership: null,
};

export const membershipStateNoDefault: MembershipState = {
 memberships: [
  {
   id: '6650c266f67f26f1a6858ae6',
   organizationId: '66057dcf153ec1fc5c238dca',
   userId: '6650c20af67f26f1a6858ae2',
   status: 0,
   companyCode: 'T600',
   roles: ['Manager'],
   permissions: ['AR', 'CHRTenders', 'Claims', 'FindLoads', 'LoadManagement'],
   isAdmin: true,
   isDefault: false,
   version: 0,
   attributes: {
    companyName: 'Blah Carrier',
    companyCode: null,
    firstName: 'Ian',
    lastName: 'Farris',
    email: 'Farrian@chrobinson.com',
    phone: '+1123456789',
   },
  },
  {
   id: '6650c20af67f26f1a6858ae3',
   organizationId: '660b28451a33f8126fa77496',
   userId: '6650c20af67f26f1a6858ae2',
   status: 0,
   companyCode: 'T126776',
   roles: ['Manager'],
   permissions: ['AR', 'CHRTenders', 'Claims', 'FindLoads', 'LoadManagement'],
   isAdmin: true,
   isDefault: false,
   version: 0,
   attributes: {
    companyName: 'rennmat org',
    companyCode: null,
    firstName: 'Ian',
    lastName: 'Farris',
    email: 'Farrian@chrobinson.com',
    phone: '+1123456789',
   },
  },
 ],
 membershipDialogView: SelectCarrierDialogViews.SET_DEFAULT,
 activeMembership: null,
};
