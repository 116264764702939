import { Action, ActionCreator, AnyAction } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { User } from 'shared/models/user.model';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';

export const LOGIN_WITH_CREDENTIALS = `${REQUEST_START}:LOGIN_WITH_CREDENTIALS`;
export const LOGIN_WITH_OTP = `${REQUEST_START}:LOGIN_WITH_OTP`;
export const LOGIN_FROM_COOKIE = `${REQUEST_START}:LOGIN_FROM_COOKIE`;
export const LOGIN_ERROR = `${REQUEST_FAILURE}:LOGIN_ERROR`;

export const REFRESH_USER = `${REQUEST_START}:REFRESH_USER`;


export interface LoginWithOTPAction extends Action {
  token: string;
}

export const loginWithOTP = (token): LoginWithOTPAction => ({
  type: LOGIN_WITH_OTP,
  token
});

export const loginError = (error: AjaxError) => ({ type: LOGIN_ERROR, error });
export const loginFromCookie = () => ({ type: LOGIN_FROM_COOKIE });

export const COMPLETE_LOGIN = `COMPLETE_LOGIN`;

export interface CompleteLoginAction extends Action {
  userJSON: UserJSON;
  carrierDetail: CarrierDetail;
}

export const completeLogin: ActionCreator<CompleteLoginAction> =
  (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({ type: COMPLETE_LOGIN, carrierDetail, userJSON });

export const LOGOUT = 'LOGOUT';
export const logout = () => ({ type: LOGOUT });

export interface StoreCredentialsAction extends Action {
  user: User;
  carrier: CarrierDetail;
}

export interface StoreIsACSUserAction extends Action {
  isACSUser: boolean;
}

export const INIT_USER = 'INIT_USER';
export const STORE_CREDENTIALS = 'STORE_CREDENTIALS';

export const initUser = () => ({ type: INIT_USER });
export const storeCredentials = (user: User, carrier: CarrierDetail): StoreCredentialsAction => ({ type: STORE_CREDENTIALS, user, carrier });

export const DEFER_ACTION_FOR_AFTER_LOGIN = 'DEFER_ACTION_FOR_AFTER_LOGIN';
export const CLEAR_POST_LOGIN_QUEUE = 'CLEAR_POST_LOGIN_QUEUE';

export const deferActionUntilPostLogin = (action: AnyAction) => ({ type: DEFER_ACTION_FOR_AFTER_LOGIN, action });
export const clearPostLoginQueue = () => ({ type: CLEAR_POST_LOGIN_QUEUE });

export const SAVE_CARRIER = 'SAVE_CARRIER';
export const saveCarrier = (payload: CarrierDetail) => ({ type: SAVE_CARRIER, payload });

export const SAVE_USER = 'SAVE_USER';
export const saveUser = (payload: User) => ({ type: SAVE_USER, payload });

export const FETCH_CARRIER_DETAIL_FOR_USER = `${REQUEST_START}:FETCH_CARRIER_DETAIL_FOR_USER`;
export const FETCH_CARRIER_DETAIL_FOR_USER_SUCCESS = `${REQUEST_SUCCESS}:FETCH_CARRIER_DETAIL_FOR_USER`;
export const FETCH_CARRIER_DETAIL_FOR_USER_FAILURE = `${REQUEST_FAILURE}:FETCH_CARRIER_DETAIL_FOR_USER`;
export const fetchCarrierForUser = (userJSON: UserJSON) => ({ type: FETCH_CARRIER_DETAIL_FOR_USER, userJSON });
export const fetchCarrierForUserSuccess = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: FETCH_CARRIER_DETAIL_FOR_USER_SUCCESS,
  userJSON,
  carrierDetail
});
export const fetchCarrierForUserError = (error: APIErrorResponse) => ({
  type: FETCH_CARRIER_DETAIL_FOR_USER_FAILURE,
  error
});

export const SET_IS_ACS_USER = 'SET_IS_ACS_USER';
export const setIsACSUser = (isACSUser: boolean): StoreIsACSUserAction => ({ type: SET_IS_ACS_USER, isACSUser });

export const CONFIRM_INSURANCE_STATUS = 'CONFIRM_INSURANCE_STATUS';
export const confirmInsuranceStatus = () => ({ type: CONFIRM_INSURANCE_STATUS });

export const LOAD_USER_DATA_SUCCESS = `${REQUEST_SUCCESS}:LOAD_USER_DATA_SUCCESS`;
export const loadUserDataSuccess = (userJSON: UserJSON) => ({ type: LOAD_USER_DATA_SUCCESS, userJSON });


export interface LoadUserDataSuccessAction extends Action {
  userJSON: UserJSON;
}

export const DISPLAY_INSURANCE_CONFIRMATION = 'DISPLAY_INSURANCE_CONFIRMATION';
export const displayInsuranceConfirmation = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: DISPLAY_INSURANCE_CONFIRMATION,
  carrierDetail,
  userJSON
});

export interface CombinedUserData {
  userJSON: UserJSON;
  carrierDetail: CarrierDetail;
}

export type CombinedUserDataAction = Action & CombinedUserData;

export const VERIFY_HAZMAT_STATUS = 'VERIFY_HAZMAT_STATUS';
export const verifyHazmatStatus = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: VERIFY_HAZMAT_STATUS,
  carrierDetail,
  userJSON
});

export const DISPLAY_HAZMAT_CONFIRMATION = 'DISPLAY_HAZMAT_CONFIRMATION';
export const displayHazmatConfirmation = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: DISPLAY_HAZMAT_CONFIRMATION,
  carrierDetail,
  userJSON
});

export const CONFIRM_HAZMAT_STATUS = 'CONFIRM_HAZMAT_STATUS';
export const confirmHazmatStatus = () => ({ type: CONFIRM_HAZMAT_STATUS });

export const VERIFY_TERMS_AND_CONDITIONS_STATUS = 'VERIFY_TERMS_AND_CONDITIONS_STATUS';
export const verifyTermsAndConditionsStatus = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: VERIFY_TERMS_AND_CONDITIONS_STATUS,
  carrierDetail,
  userJSON
});

export const DISPLAY_TERMS_AND_CONDITIONS_CONFIRMATION = 'DISPLAY_TERMS_AND_CONDITIONS_CONFIRMATION';
export const displayTermsAndConditionsConfirmation = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: DISPLAY_TERMS_AND_CONDITIONS_CONFIRMATION,
  carrierDetail,
  userJSON
});

export const CONFIRM_TERMS_AND_CONDITIONS_STATUS = 'CONFIRM_TERMS_AND_CONDITIONS_STATUS';
export const confirmTermsAndConditionsStatus = (userJSON: UserJSON, carrierDetail: CarrierDetail) => ({
  type: CONFIRM_TERMS_AND_CONDITIONS_STATUS,
  userJSON,
  carrierDetail
});
