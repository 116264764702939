import {
  AccessToken,
  IDToken,
  RefreshToken,
  UserClaims
} from "@okta/okta-auth-js/types/lib/oidc/types";
import { WidgetOptions } from "@okta/okta-signin-widget";

export enum AuthStatus {
  CLOSED,
  ACTIVE,
  LOADING,
  FAILED
}


export interface ICarrierOktaConfig extends WidgetOptions {
  audience?: string;
  domain?: string;
  clientId?: string;
  scopes?: string[];
}

/**
 * Interface for internal Okta Env HashMap
 */
export interface IOktaEnvs {
    development: ICarrierOktaConfig;
    integration: ICarrierOktaConfig;
    training: ICarrierOktaConfig;
    production: ICarrierOktaConfig;
    test: ICarrierOktaConfig;
}

export interface IOkta {
 status?: AuthStatus;
 isAuthenticated?: boolean;
 logoutWarningVisible?: boolean;
 carrierUser?: UserJSON;
 tokens?: Tokens;
 userClaims?: UserClaims;
 session?: any;
 error?: any;
 preferredEmailAddress?: string;
 preferredEmailVerifiedDate?: Date;
}

export interface OktaLoginRequest {
  bearer: string;
  issuer: string;
  token: string;
  emailAddress: string;
}

export interface idToken {
  bearer: string;
  token: string;
  claims: UserClaims;
  issuer: string;
  emailAddress: string;
}

export interface Tokens {
  idToken?: idToken | IDToken;
  iDToken?: IDToken;
  accessToken?: AccessToken;
  refreshToken?: RefreshToken;
}

export interface IOktaLoginResponse {
  code: string;
  state: string;
  status: 'SUCCESS' | string;
  tokens: {
    accessToken: AccessToken;
    refreshToken: RefreshToken;
    iDToken: IDToken;
  };
}

export interface IClaims {
  userId: string;
  carrierCode: string;
  carrierId: string;
  permissions: string;
  username: string;
  contactId: string;
}

export interface ITokens {
  accessToken: AccessToken;
  iDToken: IDToken;
  refreshToken: RefreshToken;
}
