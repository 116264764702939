
import { Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { CreateAppointment, FetchMyLoadsCriteria, LoadDriver, LoadSummary, UpdateBook } from '@features/my-loads-v2/types';
import { ResultSet } from '@shared/models/result-set.model';

@Service()
export class ShipmentsRepository extends CacheableRepository {
  constructor(url?: string) {
    super();
    this.baseUrl = url || apiConfig.shipmentsAPI;
  }

  getMyLoads(
    criteria: FetchMyLoadsCriteria,
    correlationId?: string,
    captchaToken?: string
  ): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const searchCriteria = {
      'loadNumber': criteria?.loadNumber,
      'proNumber': criteria?.proNumber,
      'referenceNumber': criteria?.referenceNumber,
      'tractorNumber': criteria?.tractorNumber,
      'trailerNumber': criteria?.trailerNumber,
      'driverId': criteria?.driverId,
      'carrierCode': criteria?.carrier,
      'status': criteria.status,
      'pageNumber': criteria?.pageNumber ? criteria.pageNumber : 1,
      'pageSize': criteria?.itemsPerPage ? criteria.itemsPerPage : 25,
      'sortField': criteria?.sortField,
      'sortDirection': criteria?.sortDirection,
      'pickupSearch': {
        'startDate': criteria?.pickup?.startDate,
        'endDate': criteria?.pickup?.endDate,
        'city': criteria?.pickup?.city,
        'state': criteria?.pickup?.state,
        'countryCode': criteria?.pickup?.country
      },
      'deliverySearch': {
        'startDate': criteria?.delivery?.startDate,
        'endDate': criteria?.delivery?.endDate,
        'city': criteria?.delivery?.city,
        'state': criteria?.delivery?.state,
        'countryCode': criteria?.delivery?.country
      }
    };

    return this.post(`/v2/shipments/my-loads`, searchCriteria, headers).map(response => {
      return response;
    });
  }

  getMyLoadsExport(
    criteria: FetchMyLoadsCriteria,
    correlationId?: string,
    captchaToken?: string
  ): Observable<ResultSet<LoadSummary>> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const searchCriteria = {
      'loadNumber': criteria?.loadNumber,
      'proNumber': criteria?.proNumber,
      'referenceNumber': criteria?.referenceNumber,
      'tractorNumber': criteria?.tractorNumber,
      'trailerNumber': criteria?.trailerNumber,
      'driverId': criteria?.driverId,
      'carrierCode': criteria?.carrier,
      'status': criteria.status,
      'pageNumber': 1,
      'pageSize': 10000,
      'sortField': criteria?.sortField,
      'sortDirection': criteria?.sortDirection,
      'pickupSearch': {
        'startDate': criteria?.pickup?.startDate,
        'endDate': criteria?.pickup?.endDate,
        'city': criteria?.pickup?.city,
        'state': criteria?.pickup?.state,
        'countryCode': criteria?.pickup?.country
      },
      'deliverySearch': {
        'startDate': criteria?.delivery?.startDate,
        'endDate': criteria?.delivery?.endDate,
        'city': criteria?.delivery?.city,
        'state': criteria?.delivery?.state,
        'countryCode': criteria?.delivery?.country
      }
    };

    return this.post(`/v2/shipments/my-loads`, searchCriteria, headers).map(response => new ResultSet<LoadSummary>(
      { results: response.items, totalRecords: response.totalCount }));
  }

  getMyLoadDetails(loadNumber: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.get(`/v2/shipments/${loadNumber}`, headers).map(response => {
      return response;
    });
  }

  getStops(loadNumber: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.get(`/v2/shipments/my-loads/${loadNumber}/stops`, headers).map(response => {
      return response;
    });
  }

  getReferenceNumbers(loadNumber: number, correlationId?: string, captchaToken?: string): Observable<any> {

    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.get(`/v2/shipments/my-loads/${loadNumber}/customer-summary`, headers).map(response => {
      return response;
    });
  }

  getApprovedCharges(shipmentId: number, correlationId?: string, captchaToken?: string): Observable<any> {

    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.get(`/v2/shipments/${shipmentId}/financials`, headers).map(response => {
      return response;
    });
  }

  updateDriver(driver: LoadDriver, shipmentId: number, bookId: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const driverData = {
      drivers: [driver]
    };

    return this.put(`/v1/shipments/${shipmentId}/books/${bookId}/drivers`, driverData, headers);
  }

  updateTractor(tractorNumber: string, shipmentId: number, bookId: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const tractorData = {
      tractorNumber: tractorNumber,
      correlationId: correlationId
    };

    return this.put(`/v1/shipments/${shipmentId}/books/${bookId}/tractor-number`, tractorData, headers);
  }

  updateTrailer(trailerNumber: string, shipmentId: number, bookId: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const trailerData = {
      trailerNumber: trailerNumber,
      correlationId: correlationId
    };

    return this.put(`/v1/shipments/${shipmentId}/books/${bookId}/trailer-number`, trailerData, headers);
  }

  updatePro(proNumber: string, shipmentId: number, bookId: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const proData = {
      proNumber: proNumber,
      correlationId: correlationId
    };

    return this.put(`/v1/shipments/${shipmentId}/books/${bookId}/pro-number`, proData, headers);
  }

  updateBook(data: UpdateBook, shipmentId: number, bookId: number, correlationId?: string, captchaToken?: string): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.put(`/v1/shipments/${shipmentId}/books/${bookId}`, data, headers);
  }

  createAppointment(
   data: CreateAppointment,
   correlationId?: string,
   captchaToken?: string
  ): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.post(`/v2/shipments/${data?.shipmentId}/books/${data?.bookId}/stops/${data?.stopId}/appointments`, data, headers);
  }

  postCancelLoad(
   shipmentId: number,
   bookId: string,
   reasonSequenceNumber: number,
   carrierCode: string,
   notes?: string,
   correlationId?: string,
   captchaToken?: string
  ): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    const bodyObj = {
      carrierCode: carrierCode,
      reason: {
        sequenceNumber: reasonSequenceNumber,
        notes: notes
      }
    };

    return this.post(`/v2/shipments/${shipmentId}/books/${bookId}:cancel`, bodyObj, headers);
  }

  getCancelLoadValidation(
   shipmentId: number,
   bookId: string,
   correlationId?: string,
   captchaToken?: string
  ): Observable<any> {
    const headers: any = {
      'X-CorrelationId': correlationId ?? '',
      'X-Captcha-Token': captchaToken,
    };

    return this.get(`/v2/shipments/${shipmentId}/books/${bookId}:validateCancellation`, null,  headers);
  }
}
