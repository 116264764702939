import { useTranslation } from 'react-i18next';
import { AppRoute } from 'app/routesEnum';
import { isAuthorized as legacyIsAuthorized } from 'features/security/firewall';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';

export enum UserPermissions {
  FIND_LOADS = 'FindLoads',
  LOAD_MANAGEMENT = 'LoadManagement',
  SPOT_BID = 'SpotBid',
  MY_SHIPMENTS = 'MyShipments',
  CHR_TENDERS = 'CHRTenders',
  TMC_TENDERS = 'TMCTenders',
  AR = 'AR',
  CLAIMS = 'Claims',
  MANAGE_INVOICE = 'ManageInvoice',
  DOCK_MGR_ADMIN = 'DockMgrAdmin',
  VIEW_RATES = 'ViewRates'
}

export const permissionsToStartPagesMap = () => {
  const permissionToPagesMap: Record<UserPermissions, string[]> = {
    [UserPermissions.FIND_LOADS]: [
      'FIND_LOADS',
      'PREFERRED_LANES',
      'POST_TRUCKS'
    ],
    [UserPermissions.LOAD_MANAGEMENT]: [
      'MY_LOADS',
      'CARRIER_ADVANTAGE'
    ],
    [UserPermissions.SPOT_BID]: ['SPOT_BIDS'],
    [UserPermissions.MY_SHIPMENTS]: ['MY_SHIPMENTS'],
    [UserPermissions.CHR_TENDERS]: ['CHRW_TENDERS'],
    [UserPermissions.TMC_TENDERS]: ['TMC_TENDERS'],
    [UserPermissions.AR]: ['ACCOUNTS_RECEIVABLE'],
    [UserPermissions.CLAIMS]: ['CLAIMS'],
    [UserPermissions.MANAGE_INVOICE]: ['MANAGE_INVOICES'],
    [UserPermissions.VIEW_RATES]: ['VIEW_RATES'],
    [UserPermissions.DOCK_MGR_ADMIN]: [] // no page exists for this permission
  };

  return permissionToPagesMap;
};

const permissionsToStartPagesMenuItemsMap = () => {
  const { t } = useTranslation();

  // Mapping of permissions to pages (with label and value)
  const permissionToPagesMap: Record<UserPermissions, Array<{ label: string, value: string }>> = {
    [UserPermissions.FIND_LOADS]: [
      { label: t('FIND_LOADS'), value: 'FIND_LOADS' },
      { label: t('PREFERRED_LANES'), value: 'PREFERRED_LANES' },
      { label: t('POST_TRUCKS'), value: 'POST_TRUCKS'}
    ],
    [UserPermissions.LOAD_MANAGEMENT]: [
      { label: t('MY_LOADS'), value: 'MY_LOADS' },
      { label: t('CARRIER_ADVANTAGE'), value: 'CARRIER_ADVANTAGE'}
    ],
    [UserPermissions.SPOT_BID]: [{ label: t('SPOTBIDS'), value: 'SPOT_BIDS' }],
    [UserPermissions.MY_SHIPMENTS]: [{ label: t('MY_SHIPMENTS'), value: 'MY_SHIPMENTS' }],
    [UserPermissions.CHR_TENDERS]: [{ label: t('CHRW_TENDERS'), value: 'CHRW_TENDERS' }],
    [UserPermissions.TMC_TENDERS]: [{ label: t('TMC_TENDERS'), value: 'TMC_TENDERS' }],
    [UserPermissions.AR]: [{ label: t('ACCOUNTS_RECEIVABLE'), value: 'ACCOUNTS_RECEIVABLE' }],
    [UserPermissions.CLAIMS]: [{ label: t('CLAIMS'), value: 'CLAIMS' }],
    [UserPermissions.MANAGE_INVOICE]: [{ label: t('MANAGE_INVOICES'), value: 'MANAGE_INVOICES' }],
    [UserPermissions.VIEW_RATES]: [{ label: t('VIEW_RATES'), value: 'VIEW_RATES' }],
    [UserPermissions.DOCK_MGR_ADMIN]: [] // no page exists for this permission
  };

  return permissionToPagesMap;
}

export const getStartPagesAvailableToUser = (permissions: Array<string>) => {
  const permissionToPagesMap = permissionsToStartPagesMenuItemsMap();

  if (!permissions) {
    return [];
  }

  const availablePages = permissions.map((permission) => permissionToPagesMap[permission as keyof typeof UserPermissions]);
  return [].concat(...availablePages);
};

 export const getRoutesByUMPermissions = (UMPermissions) => {
   const permissionsToPagesMap = permissionsToStartPagesMap();
   const availablePages = UMPermissions.map((permission) => permissionsToPagesMap[permission as keyof typeof UserPermissions]);
   return [].concat(...availablePages); 
 }

const routeToPermissionMap: { [key: string]: UserPermissions[] } = {
  [AppRoute.FIND_LOADS]: [UserPermissions.FIND_LOADS],
  [AppRoute.OFFERS]: [UserPermissions.FIND_LOADS],
  [AppRoute.POST_TRUCKS]: [UserPermissions.FIND_LOADS],
  [AppRoute.PREFERRED_LANES]: [UserPermissions.FIND_LOADS],
  [AppRoute.FIND_LOADS_BASE]: [UserPermissions.FIND_LOADS],
  [AppRoute.FIND_LOAD_DETAILS]: [UserPermissions.FIND_LOADS],
  [AppRoute.MY_LOADS]: [UserPermissions.LOAD_MANAGEMENT],
  [AppRoute.MY_LOAD_DETAILS]: [UserPermissions.LOAD_MANAGEMENT],
  [AppRoute.CARRIER_ADVANTAGE_PROGRAM]: [UserPermissions.LOAD_MANAGEMENT],
  [AppRoute.DASHBOARD]: [UserPermissions.LOAD_MANAGEMENT],
  [AppRoute.SPOT_BIDS]: [UserPermissions.SPOT_BID],
  [AppRoute.MY_SHIPMENTS]: [UserPermissions.MY_SHIPMENTS],
  [AppRoute.MY_SHIPMENT_DETAILS]: [UserPermissions.MY_SHIPMENTS],
  [AppRoute.TENDERS]: [UserPermissions.CHR_TENDERS, UserPermissions.TMC_TENDERS],
  [AppRoute.TENDERS_DEEP_LINK]: [UserPermissions.CHR_TENDERS, UserPermissions.TMC_TENDERS],
  [AppRoute.ACCOUNTS_RECEIVABLE]: [UserPermissions.AR],
  [AppRoute.CLAIMS]: [UserPermissions.CLAIMS],
  [AppRoute.MANAGE_INVOICES]: [UserPermissions.MANAGE_INVOICE],
  [AppRoute.DOCK_MANAGER]: [UserPermissions.DOCK_MGR_ADMIN],
  [AppRoute.RATES]: [UserPermissions.VIEW_RATES],
  [AppRoute.RESOLVE_MODAL]: [UserPermissions.LOAD_MANAGEMENT]
};

export const isAuthorized = (permissions) => {
  const userPermissions = useSelector(s => s.userManagement?.activeMembership?.permissions);
  const carrier = useSelector(s => s.auth.carrier);
  const hasPermission = permissions && userPermissions && userPermissions.some(permission => permissions.includes(permission));
  
  // don't authorize if we don't have carrier details in store yet
  if (!carrier) return false;

  return hasPermission;
}

export const useAuthorization = (url: AppRoute) => {
  const { enableUserManagement } = useFlags();
  // TODO: remove these once user management is on %100
  const isACSUser = useSelector(state => state.auth.isACSUser);
  const user = useStoredUser();
  const carrier = useStoredCarrierDetail();

  // find loads has variable search types so map all to find loads route
  if (url?.startsWith('/find-loads')) {
    url = AppRoute.FIND_LOADS;
  }

  const permissionsForRoute = routeToPermissionMap[url] || null;
  const authorized = isAuthorized(permissionsForRoute);

  // TODO: remove conditional flag logic when user management is fully released
  if (enableUserManagement && permissionsForRoute && !authorized) { //only evaluate authorization if the route requires permissions
    return (false);
  } else if (!enableUserManagement && !legacyIsAuthorized(user, url, carrier, isACSUser)) {
    return(false);
  } 
  return (true);
};

export const needsToUpdateProfile = () => {
  const user = useStoredUser();
  
  if(user && user.firstName?.toLowerCase() === 'carrier' && user.lastName?.toLowerCase() === 'user') {
    return true;
  }

  return false;
}

