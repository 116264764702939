import { CashAdvanceSourceType } from 'shared/models/cash-advances/cash-advance-options-source.model';
import { CashAdvanceOptions } from 'shared/models/cash-advances/cash-advance-options.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { CashAdvance } from 'shared/models/cash-advances/cash-advance.model';
import { Action } from 'redux';
import { AnalyticsActionTypes } from '../analytics-action-types';
import { CashAdvanceSourceTypeV3 } from '@shared/models/cash-advances/cash-advance-options-source-V3.model';

export interface SubmitSearchEvent {
  searchCriteria: AvailableLoadSearchCriteriaJSON;
  isButtonClick?: boolean;
  searchCorrelationId?: string;
}

export interface RecentSearchSelectionEvent {
  isRecentSearchSelection: boolean;
  locationField: string;
}

export interface SearchResultsEvent {
  pageSize?: number;
  pageNumber?: number;
  recommendedLoads?: AvailableLoadSummary[];
  searchResults?: AvailableLoadSummary[];
  totalVisibleResults?: number;
  subsetOfResultsCount?: number;
}

export interface SetReloadsSearchEvent {
  reloads?: AvailableLoadSummary[];
  primalLoadId?: number;
  searchCorrelationId?: string;
  callVariation?: ReloadsCallVariation;
}

export interface SortAction {
  sortBy: string;
  sortOrder: SortOrder;
  sortedResults: AvailableLoadSummary[];
}

export interface ReloadEventData {
  callVariation: ReloadsCallVariation;
  primalLoadNumber: number;
  reloadNumber: number;
}

// Analytics actions
export interface AnalyticsEventsAction extends Action<AnalyticsActionTypes> {}
export interface LoginEventsAction extends AnalyticsEventsAction {}

export interface SearchEventsAction extends AnalyticsEventsAction {
  payload: SubmitSearchEvent | SearchResultsEvent | RecentSearchSelectionEvent;
}
export interface SortEventsAction extends AnalyticsEventsAction {
  payload: SortAction;
}
export interface ReloadsEventsAction extends AnalyticsEventsAction {
  payload: SetReloadsSearchEvent;
}
export interface CashAdvanceEventsAction extends AnalyticsEventsAction {
  payload?: {
    load?: ExtendedLoadInterface;
    cashAdvanceOptions?: CashAdvanceOptions;
    requestedAmount?: number;
    type?: CashAdvanceSourceType | CashAdvanceSourceTypeV3;
    transactionFee?: number;
    responseCode?: number;
    cashAdvance?: CashAdvance;
    passcodeOutcome?: boolean;
  };
}

// Action Creators
// Cash Advance
export const setCashAdvanceLoad = (load: ExtendedLoadInterface): CashAdvanceEventsAction => ({ type: AnalyticsActionTypes.SET_CASH_ADVANCE_LOAD, payload: { load } });
export const setCashAdvanceOptions = (cashAdvanceOptions: CashAdvanceOptions): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_OPTIONS,
  payload: { cashAdvanceOptions },
});
export const setCashAdvanceSubmitEvent = (requestedAmount: number, type: CashAdvanceSourceType | CashAdvanceSourceTypeV3, transactionFee: number): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_SUBMIT_EVENT,
  payload: { requestedAmount, type, transactionFee },
});
export const setCashAdvanceSuccessEvent = (cashAdvance: CashAdvance): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_SUCCESS_EVENT,
  payload: { cashAdvance },
});
export const setCashAdvanceFailureEvent = (responseCode: number, passcodeOutcome: boolean): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_ERROR_EVENT,
  payload: { responseCode, passcodeOutcome },
});
export const setCashAdvanceReset = () => ({ type: AnalyticsActionTypes.SET_CASH_ADVANCE_RESET });
export const setCashAdvanceRequestEvent = () => ({ type: AnalyticsActionTypes.SET_CASH_ADVANCE_REQUEST_EVENT });
export const setCashAdvanceContinueEvent = (requestedAmount: number, type: CashAdvanceSourceType | CashAdvanceSourceTypeV3, transactionFee: number): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_CONTINUE_EVENT,
  payload: { requestedAmount, type, transactionFee },
});
export const setCashAdvanceReviewContinueEvent = (requestedAmount: number, type: CashAdvanceSourceType | CashAdvanceSourceTypeV3, transactionFee: number): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_REVIEW_CONTINUE_EVENT,
  payload: { requestedAmount, type, transactionFee },
});
export const setCashAdvanceViewSuccessEvent = (cashAdvance: CashAdvance): CashAdvanceEventsAction => {
  return {
    type: AnalyticsActionTypes.SET_CASH_ADVANCE_VIEW_SUCCESS_EVENT,
    payload: { cashAdvance },
  };
};
export const setCashAdvanceViewFailureEvent = (responseCode: number, passcodeOutcome: boolean): CashAdvanceEventsAction => ({
  type: AnalyticsActionTypes.SET_CASH_ADVANCE_VIEW_ERROR_EVENT,
  payload: { responseCode, passcodeOutcome },
});
export const setCashAdvanceViewReset = () => ({ type: AnalyticsActionTypes.SET_CASH_ADVANCE_VIEW_RESET });

// Login
export const setLoginViewEvent = (): LoginEventsAction => ({ type: AnalyticsActionTypes.SET_LOGIN_VIEW_EVENT });
export const setLoginSubmitEvent = (): LoginEventsAction => ({ type: AnalyticsActionTypes.SET_LOGIN_SUBMIT_EVENT });
export const setLoginSuccessEvent = (): LoginEventsAction => ({ type: AnalyticsActionTypes.SET_LOGIN_SUCCESS_EVENT });
export const setLoginFailureEvent = (): LoginEventsAction => ({ type: AnalyticsActionTypes.SET_LOGIN_FAILURE_EVENT });

// Search
export const setSubmitSearchEvent = (submitSearchEvent: SubmitSearchEvent): SearchEventsAction => ({
  type: AnalyticsActionTypes.SET_SUBMIT_SEARCH_EVENT,
  payload: submitSearchEvent,
});
export const setSearchResultsEvent = (searchResultsEvent: SearchResultsEvent): SearchEventsAction => ({
  type: AnalyticsActionTypes.SET_SEARCH_RESULTS_EVENT,
  payload: searchResultsEvent,
});
export const setEmptySearchResultsEvent = (): SearchEventsAction => ({ type: AnalyticsActionTypes.SET_SEARCH_RESULTS_EVENT, payload: { searchResults: [] } });
export const setRecommendedLoadListViewedEvent = (searchResultsEvent: SearchResultsEvent): SearchEventsAction => ({
  type: AnalyticsActionTypes.SET_RECOMMENDED_LOAD_LIST_VIEWED_EVENT,
  payload: searchResultsEvent,
});
export const setInitialSearchCorrelationId = (): AnalyticsEventsAction => ({ type: AnalyticsActionTypes.SET_INITIAL_SEARCH_CORRELATION_ID });
export const setRecentSearchSelectionEvent = (recentSearchSelectionEvent: RecentSearchSelectionEvent): SearchEventsAction => ({
  type: AnalyticsActionTypes.SET_RECENT_SEARCH_SELECTION_EVENT,
  payload: recentSearchSelectionEvent,
});
// Sort
export const setSortActionEvent = (sortAction: SortAction): SortEventsAction => ({ type: AnalyticsActionTypes.SET_SORT_ACTION_EVENT, payload: sortAction });
// Recommended load list viewed

// Reloads
export const setReloadsSearchEvent = (reloadsEvent: SetReloadsSearchEvent): ReloadsEventsAction => ({ type: AnalyticsActionTypes.SET_RELOADS, payload: reloadsEvent });

// LD user identification action
export interface LdUserIdentityAction {
  type: AnalyticsActionTypes;
  payload: boolean;
}

export const setLdUserIdentity = (isIdentified?: boolean): LdUserIdentityAction => ({
  type: AnalyticsActionTypes.SET_LD_USER_IDENTIFIED,
  payload: isIdentified,
});
