import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { checkMembership } from 'features/permissions';
import { IClaims } from '@features/okta/types';
import { AccessToken } from '@okta/okta-auth-js/types/lib/oidc/types';
import { getRoutesByUMPermissions } from 'features/permissions/hooks/permissionsHooks';

const Region = {
  NorthAmerica: 0,
  SouthAmerica: 1,
  Europe: 3
};

const enum BusinessUnit {
  NAST = 0,
  TMC = 2,
  EUSTTL = 3
}

const CertificationType = {
  Hazmat: 1,
}

const regionToContinentCodeMap = {
  [Region.NorthAmerica]: "NA",
  [Region.SouthAmerica]: "SA",
  [Region.Europe]: "EU"
};

export const extractClaims = (token: AccessToken) => ({
  carrierCode: token.claims['carrierCode'],
  carrierId: token.claims['carrierId'],
  permissions: token.claims['carrierPermissions'],
  username: token.claims['carrierUsername'],
  userId: token.claims['userId'],
  contactId: token.claims['userContactId']
}) as IClaims;

export const mapOrganizationToCarrierDetail = (organization: Organization): CarrierDetail => {
  const carrierDetails = {
    name: organization?.name,
    carrierCode: organization?.companyCode,
    capCode: organization?.attributes?.strategicRelationship,
    status: organization?.status,
    carrierQualificationStatus: organization?.attributes?.carrierQualificationStatus,
    isInsuranceRequired: organization?.insurances?.length > 0,
    carrierInsurance: organization?.insurances?.map(insurance => ({ insuranceType: insurance.type, insuranceStatus: insurance.status, insuranceExpiration: insurance.expirationDate })),
    carrierCertifications: organization?.isHazmatQualified
      ? [
          {
          type: CertificationType.Hazmat,
          description: 'Hazmat',
          isQualified: organization?.isHazmatQualified,
          certification: {
            status: organization?.attributes?.hazmatQualifications?.status,
            expirationDateTime: null,
            effectiveDateTime: null,
            registrationNumber: null,
          },
          },
        ]
      : [],
    carrierSize: organization?.segment,
    rollups: organization?.rollups.map(rollup => ({ name: rollup.name, code: rollup.code, division: rollup.businessUnit, isTMC: rollup.businessUnit === BusinessUnit.TMC})),
    type: organization?.type,
    continentCode: regionToContinentCodeMap[organization?.region],
    businessTypes: organization?.attributes?.businessTypes,
    divisions: organization?.rollups.map(rollup => rollup.businessUnit),
    communicationPreferences: null // TODO figure out mapping
  }

  return new CarrierDetail(carrierDetails);
}

export const mapUserToUserModel = (user: MembershipsUser, claims: IClaims, activeMembership: Membership) => {

  const carrierUser = {
    userID: claims?.carrierId ? Number(claims?.carrierId) : Number(claims?.userId),
    contactId: Number(claims?.contactId),
    username: claims.username,
    permissions: null,
    firstName: user?.firstName,
    lastName: user?.lastName,
    emailAddress: user?.email,
    properties: {
      country: user?.attributes?.country,
      culture: user?.attributes?.culture,
      startPage: user?.attributes?.startPage || (activeMembership ? getSuggestedStartPage(activeMembership.permissions) : null),
      timeFormat: user?.attributes?.timeFormat,
      timeZone: user?.attributes?.timeZone || 'Central Standard Time',
      unitOfMeasure: user?.attributes?.unitOfMeasure,
      disableLoadCheckinUpdate: null, // understand if we need this
      disableLoadDropUpdate: null, // same
      disableLoadPickUpdate: null, // same
      preferredCurrencyCode: user?.attributes?.preferredCurrencyCode || 'USD',
      hasVerifiedPhone: user?.attributes?.hasVerifiedPhone
    },
    phoneNumber: user?.attributes?.phone,
    isDriver: null,
    acceptedAgreement: true // TODO close gap
  };

  return carrierUser;
}

const getSuggestedStartPage = (permissions: Array<string>): string => {
  const availableStartPages = getRoutesByUMPermissions(permissions);
    if (availableStartPages.includes('FIND_LOADS')) {
        return 'FIND_LOADS';
    } else if (availableStartPages.includes('MY_LOADS')) {
        return 'MY_LOADS';
    } else if (availableStartPages.includes('ACCOUNTS_RECEIVABLE')) {
        return 'ACCOUNTS_RECEIVABLE';
    } else {
        return availableStartPages[0]?.value;
    }
};

export const determineActiveMembership = (memberships): Membership => {
  const { currentMembershipSelection, defaultMembership, hasOnlyOneMembership } = checkMembership(memberships);

  // find membership that we pulled from api that matches the id to membership from local storage so that we get an updated membership object in case of changes
  const membershipMappedFromStorage = memberships.find(m => m.id === currentMembershipSelection?.id);
  if (membershipMappedFromStorage) {
    return membershipMappedFromStorage;
  } else if (defaultMembership) {
    return defaultMembership;
  } else if (hasOnlyOneMembership) {
    return memberships[0];
  } else {
    return null;
  }
}

