import moment from 'moment';
import { CarrierDivision } from 'shared/models/carrier';
import { BaseModel } from 'shared/models/base.model';
import { CarrierInsurance, InsuranceStatus } from 'shared/models/carrier/carrier-insurance.model';
import { CarrierCertificate, CarrierCertification } from 'shared/models/carrier/carrier-certification.model';
import { CertificationType, CertificationStatus } from 'shared/enums/enums';
import { OrganizationTypes } from 'features/account-settings/manage-users/enums';

export class CarrierDetail extends BaseModel<CarrierDetailJSON> implements CarrierDetailInterface {
  readonly name: string;
  readonly carrierCode: string;
  readonly capCode: string;
  readonly carrierQualificationStatus: string;
  readonly isInsuranceRequired: boolean;
  readonly carrierInsurance: CarrierInsurance[] = [];
  readonly carrierCertifications: CarrierCertification[] = [];
  readonly continentCode: string;
  readonly carrierSize: string;
  readonly type: OrganizationTypes;
  readonly businessTypes: string[];
  readonly carrierDivision: CarrierDivision;
  readonly communicationPreferences: {
    [name: string]: string;
  };

  public _hazmatCert: CarrierCertification;
  public rollups: rollupCarrier[] = [];
  public divisions: number[] = [];

  constructor(json?: CarrierDetailJSON) {
    super(json);

    if (json) {
      this.carrierInsurance = json.carrierInsurance ? json.carrierInsurance.map(item => new CarrierInsurance(item)) : [];
      this.carrierCertifications = json.carrierCertifications ? json.carrierCertifications.map(item => new CarrierCertification(item)) : [];
      this.businessTypes = json.businessTypes ? json.businessTypes.map(item => item) : [];
      this.rollups = json.rollups ? json.rollups.map(rollup => rollup) : [];
      this._hazmatCert = this.carrierCertifications.find(cert => cert.type === CertificationType.Hazmat);

      if (Array.isArray(json.divisions)) {
        this.carrierDivision = new CarrierDivision(json.divisions);
        this.divisions = json.divisions;
      } else {
        this.carrierDivision = new CarrierDivision([]);
        this.divisions = [];
      }
    }
  }

  toJson(): CarrierDetailJSON {
    const response: CarrierDetailJSON = Object.assign({}, this as any);

    response.carrierInsurance = this.carrierInsurance.map(insurance => insurance.toJson());

    return response;
  }

  public hasExpiredInsurance(): boolean {
    return this.carrierInsurance.reduce((result, insurance) => result || insurance.insuranceStatus === InsuranceStatus.Expired, false);
  }

  public hasExpiringInsurance(): boolean {
    return this.carrierInsurance.reduce((result, insurance) => result || insurance.insuranceStatus === InsuranceStatus.ExpiresSoon, false);
  }

  get daysUntilInsuranceExpiration() {
    return this.carrierInsurance
      .map(insurance => insurance.daysUntilExpiration)
      .reduce((currentSoonest, days) => Math.min(currentSoonest, days), 10000);
  }

  private get hazmatCertification(): CarrierCertification {
    if (!this._hazmatCert) {
      this._hazmatCert = this.carrierCertifications.find(cert => cert.type === CertificationType.Hazmat);
    }
    return this._hazmatCert;
  }

  private get hazmatCertificate(): CarrierCertificate {
    return this.hazmatCertification?.certification;
  }

  public get isCarrierCodeOdd(): boolean {
    return Boolean(parseInt(this.carrierCode?.trim().slice(-1), 10) % 2 !== 0);
  }

  private get hasHazmatCertification(): boolean {
    return Boolean(this.hazmatCertificate?.hasOwnProperty('status'));
  }

  public hasExpiredHazmat(): boolean {
    return this.hasHazmatCertification && this.hazmatCertificate.status === CertificationStatus.Expired;
  }

  public hasValidHazmatCertification(): boolean {
    return this.hasHazmatCertification && this.hazmatCertificate.status === CertificationStatus.Valid;
  }

  public hasExpiringHazmat(): boolean {
    return this.hasHazmatCertification && this.hazmatCertificate.status === CertificationStatus.ExpiresSoon;
  }

  get daysUntilHazmatExpiration(): number {
    if (!this.hasHazmatCertification) {
      return null;
    }
    const today = moment().startOf('day');
    const expiration = moment(this.hazmatCertificate.expirationDateTime).startOf('day');

    return expiration.diff(today, 'days');
  }

  get typeOfInsurance(): number {
    return this.carrierInsurance.map(insurance => insurance.typeOfInsurance).reduce((currentSoonest, days) => Math.min(currentSoonest, days));
  }

  public isNorthAmerican(): boolean {
    return this.continentCode ? this.continentCode === 'NA' : this.getRollup(this.carrierCode)?.regionCode === 'NA';
  }

  public isEuropean(): boolean {
    return this.continentCode ? this.continentCode === 'EU' : this.getRollup(this.carrierCode)?.regionCode === 'EU';
  }

  public isTMC(): boolean {
    return this.type ? this.type === OrganizationTypes.TMC : this.getRollup(this.carrierCode)?.isTMC;
  }

  public isDray(): boolean {
    return this.businessTypes.includes('DRAY');
  }

  public getRollup(carrierCode: string): rollupCarrier {
    return this.rollups.find(rollup => rollup.code === carrierCode);
  }

  public getContinentCode(): string {
    return this.continentCode ? this.continentCode : this.getRollup(this.carrierCode)?.regionCode;
  }
}
