import { useState, useEffect } from 'react';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { AppRoute } from '@app/routesEnum';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CheckBox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { Button, useSnackbar } from '@chr/eds-react';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import { logOut } from '@features/okta/redux/oktaSlice';
import { push } from 'connected-react-router';
import {
 CompanySelectRadio,
 getSnackbarProps,
 snackbarTypes,
 checkMembership,
 updateDefaultMembership,
 toggleMembershipDialog,
 CompanySelectionDialogViews,
 setActiveMembership
} from 'features/permissions';

import {
 contentContainer,
 headerContainer,
 body,
 bodyBlock,
 bodyShortScreen,
 dialogContainer,
 checkBoxSection,
 checkBox,
 membershipContainer,
 continueButtonContainer,
} from 'features/permissions/styles';
import { useUserAndCarrierDataContext } from 'features/userAndCarrierData';

interface SelectCarrierDialogProps {
 t: any;
}

export const SelectCarrierDialog = ({ t }: SelectCarrierDialogProps) => {
 const user = useStoredUser();
 const dispatch = useDispatch();
 const snackbar = useSnackbar();
 const { updateCarrierDetails } = useUserAndCarrierDataContext();
 const bodyStyle = useMediaQuery('(max-height:650px)') ? bodyShortScreen : body;
 const [isDefault, setIsDefault] = useState(false);
  const [didUpdateMembership, setDidUpdateMembership] = useState(false);
 const { memberships, updateStatus, showCarrierSelectDialog, membershipDialogView } = useSelector(s => s.userManagement);

 const { currentMembershipSelection, defaultMembership, hasOnlyOneMembership, shouldHideDialog} = checkMembership(memberships);
 const [selectedMembership, setSelectedMembership] = useState(null);
  const isSetDefault = membershipDialogView === CompanySelectionDialogViews.SET_DEFAULT;
 const isInvitationAccepted = membershipDialogView === CompanySelectionDialogViews.INVITATION_ACCEPTED;
 const companyName = selectedMembership?.attributes?.companyName;
 const companyCode = selectedMembership?.companyCode;

 const { headerResource, subtitleResource } = {
  headerResource: t(isSetDefault ? 'SELECT_DEFAULT_COMPANY' : 'CHANGE_COMPANY'),
  subtitleResource: t(isSetDefault ? 'COMPANY_CODE_SELECTION_PARAGRAPH' : 'SELECT_THE_ACCOUNT_YOU_WANT'),
 };

  const setOrganization = (selectedMembership) => {
    dispatch(setActiveMembership(selectedMembership));
    localStorage.setItem('active-membership', JSON.stringify(selectedMembership));
    try {
      // update carrier details when changing membership
      updateCarrierDetails(selectedMembership.organizationId);
    } catch (err) {
      console.error(JSON.stringify(err));
      dispatch(logOut());
    }
  };

  const setPreselectedMembership = () => {
    // set selected membership to current membership in store if exists, default membership next if exists, if not first membership in array
    if (currentMembershipSelection) {
      setSelectedMembership(currentMembershipSelection);
    } else if (defaultMembership) {
      setSelectedMembership(defaultMembership);
    } else if (hasOnlyOneMembership) {
      setSelectedMembership(memberships[0]);
    } else {
      dispatch(toggleMembershipDialog(true)); // show dialog if no preselectedMembership exists
    }
  };

  useEffect(() => { 
    if (!!user && memberships) {
      setPreselectedMembership();
    }
  }, [user, memberships]);

 useEffect(() => {
  if (updateStatus != null) handleDefaultUpdated(updateStatus === 'SUCCESS');
 }, [updateStatus]);

 const handleClose = () => {
   dispatch(toggleMembershipDialog(false));
   setIsDefault(false);
 };

 const handleClick = () => {
  // reset didUpdateMembership on click, if membership selection does change then set to true in if statement
  setDidUpdateMembership(false);
  // update user permissions and active membership if selected != active
  if (!isEqual(selectedMembership?.id, currentMembershipSelection?.id)) {
   setOrganization(selectedMembership);
   localStorage.setItem('active-membership', JSON.stringify(selectedMembership));
    setDidUpdateMembership(true);
  }

  if (isDefault && !selectedMembership?.isDefault) {
   dispatch(updateDefaultMembership({ userId: selectedMembership?.userId, membershipId: selectedMembership?.id }));
   return;
  }
  snackbar.enqueueSnackbar(getSnackbarProps(snackbarTypes.CHANGED_COMPANY_SUCCESS, t, companyName, companyCode));
  handleClose();
  
  if (isInvitationAccepted) {
    dispatch(push(AppRoute.HOME));
  }
 };

 const handleDefaultUpdated = async (ok = true) => {
  if (!ok) {
   snackbar.enqueueSnackbar(getSnackbarProps(snackbarTypes.CHANGED_COMPANY_ERROR, t));
   return;
  }
  didUpdateMembership 
    ? snackbar.enqueueSnackbar(getSnackbarProps(snackbarTypes.CHANGED_COMPANY_AND_DEFAULT_SUCCESS, t, companyName, companyCode)) 
    : snackbar.enqueueSnackbar(getSnackbarProps(snackbarTypes.CHANGED_DEFAULT_COMPANY_SUCCESS, t))

  handleClose();
 };

  if (!memberships || (shouldHideDialog && isSetDefault)) return;

 return (
  <Dialog fullScreen maxWidth={false} open={showCarrierSelectDialog} onClose={handleClose} sx={dialogContainer}>
   <Box sx={contentContainer}>
    <Box sx={headerContainer}>
     <Typography data-testid="selectCarrierHeader" variant="h1">
      {headerResource}
     </Typography>
     {!isSetDefault && !isInvitationAccepted && (
      <IconButton onClick={handleClose} sx={{ color: 'black' }} data-testid={'close-btn'}>
       <CloseIcon />
      </IconButton>
     )}
    </Box>
    <Box sx={bodyStyle}>
     {isSetDefault && (
      <Box>
       <Typography variant="h1">{t('WELCOME_FIRST_NAME', { 0: user?.firstName })}</Typography>
      </Box>
     )}
     <Box sx={bodyBlock}>
      <Typography variant="subtitle1">{subtitleResource}</Typography>
     </Box>
     <Box sx={membershipContainer}>
      {Object.entries(memberships as Membership).map(([key, { companyCode, ...membership }]) => (
       <CompanySelectRadio
        data-testid="companySelectRadio"
        key={key}
        membership={membership}
        isChecked={selectedMembership?.companyCode === companyCode}
        handleChange={() => setSelectedMembership({ companyCode, ...membership })}
       />
      ))}
     </Box>
     <Stack direction="row" spacing={2} sx={checkBoxSection}>
      <CheckBox data-testid="checkboxId" checked={isDefault} value={isDefault} onChange={() => setIsDefault(!isDefault)} sx={checkBox} />
      <Box>
       <Typography variant="subtitle2">{t('SET_AS_MY_DEFAULT_ACCOUNT')}</Typography>
       <Typography variant="body2">{t('YOU_CAN_CHANGE_THIS_SETTING')}</Typography>
      </Box>
     </Stack>
     <Box sx={continueButtonContainer}>
      <Button buttonText={t('CONTINUE')} onClick={handleClick} fullWidth />
     </Box>
    </Box>
   </Box>
  </Dialog>
 );
};
