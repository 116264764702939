import { Container } from 'typedi';
import { ajax } from 'rxjs/observable/dom/ajax';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { logout } from '@features/security/auth.actions';
import { Store } from 'store';
import { Headers, Http } from '@app/globals/constants';
import { generateQueryString } from 'providers/query-string.provider';
import { environment } from 'environments/environment';
import { generateRequestHeaders, generateXDate } from "api/utils";
import { AuthAction, DataDogLogger } from '@features/datadog';
import { OktaAuth, OktaAuthIdxOptions } from '@okta/okta-auth-js';
import { getOktaConfig } from '@features/okta/config';
import { switchMap } from 'rxjs/operators';

export class BaseRepository {
 protected generateQueryString = generateQueryString;

 // @Inject causes circular references
 protected get store() {
  return Container.get(Store);
 }

 protected getUrl(path) {
  return this.baseUrl + path;
 }

 protected oktaAuth = new OktaAuth(getOktaConfig() as OktaAuthIdxOptions);
 constructor(protected baseUrl = '') { }

  get(path: string, headers?: AuthRequestHeaders, responseType = 'json'): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.GET, url, headers)).pipe(
      switchMap(updatedHeaders => {
        return ajax({ method: Http.GET, url, responseType, headers: updatedHeaders })
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  async getPromise(path: string, headers?: AuthRequestHeaders, responseType = 'json'): Promise<any> {
    const url = this.getUrl(path);
    const updatedHeaders = await this.updateRequestHeaders(Http.GET, url, headers);
    return ajax({ method: Http.GET, url, responseType, headers: updatedHeaders })
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .toPromise()
      .catch(err => this.catchAuthorizationErrors(err));
  }

  put(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.PUT, url, headers)).pipe(
      switchMap(updatedHeaders => {
        return ajax.put(url, body, updatedHeaders)
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  patch(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.PATCH, url, headers)).pipe(
      switchMap(updatedHeaders => {
        return ajax.patch(url, body, updatedHeaders)
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  post(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.POST, url, headers)).pipe(
      switchMap(updatedHeaders => {
        return ajax.post(url, body, updatedHeaders)
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  async postPromise(path: string, body: any, headers?: AuthRequestHeaders): Promise<any> {
    const url = this.getUrl(path);
    const updatedHeaders = await this.updateRequestHeaders(Http.POST, url, headers);
    return ajax.post(url, body, updatedHeaders)
      .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
      .toPromise()
      .catch(err => this.catchAuthorizationErrors(err));
  }

  postWithRequest(ajaxRequest: AjaxRequest): Observable<any> {
    ajaxRequest.url = this.getUrl(ajaxRequest?.url);
    return from(this.updateRequestHeaders(Http.POST, ajaxRequest.url, ajaxRequest.headers)).pipe(
      switchMap(updatedHeaders => {
        ajaxRequest.headers = updatedHeaders;
        return ajax(ajaxRequest)
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  postWithMultipart(path: string, body: any, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.POST, url, headers)).pipe(
      switchMap(updatedHeaders => {
        // MUST allow the browser to set the content-type header so that the form boundaries can be pulled in.
        delete updatedHeaders[Headers.CONTENT_TYPE];
        return ajax.post(url, body, updatedHeaders)
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  getBlob(path: string, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.GET, url, headers)).pipe(
      switchMap(updatedHeaders => {
        return ajax({ method: Http.GET, url, headers: updatedHeaders, responseType: 'blob' })
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

  delete(path: string, headers?: AuthRequestHeaders): Observable<any> {
    const url = this.getUrl(path);
    return from(this.updateRequestHeaders(Http.DELETE, url, headers)).pipe(
      switchMap(updatedHeaders => {
        return ajax.delete(url, updatedHeaders)
          .map((ajaxResponse: AjaxResponse) => ajaxResponse.response)
          .catch(err => this.catchAuthorizationErrors(err));
      })
    );
  }

 protected catchAuthorizationErrors(error: AjaxError): ErrorObservable<any> {
  if (error.status === 401) {
    if ((error.request?.headers as { Authorization?: string })?.Authorization === 'Bearer null') {
      DataDogLogger.trackAction(AuthAction.NoAccessToken, { error });
    } else {
      DataDogLogger.trackAction(AuthAction.AuthenticationFailure, { error });
    }
   this.oktaAuth.tokenManager.clear();
   this.store.dispatch(logout());
  }

  return Observable.throw(error);
 }

 protected async updateRequestHeaders(method: string, url: string, headers?: AuthRequestHeaders): Promise<AuthRequestHeaders> {
   const token = await this.oktaAuth.getOrRenewAccessToken();
   const xBearerAuthorization = token
   ? { [Headers.X_BEARER_AUTHORIZATION]: `Bearer ${token}` }
   : {};

  if (!headers) {
   headers = {
    ...this.generateHeaders(method, url, 'application/json'),
    ...xBearerAuthorization,
   };
  } else {
   let contentType = headers[Headers.CONTENT_TYPE] || 'application/json';
   const accept = headers[Headers.ACCEPT];

   if (contentType === 'multipart/form-data') {
    contentType = null;
   }

   const newHeaders = this.generateHeaders(method, url, contentType, accept);

   headers = { ...headers, ...newHeaders };
  }
  return headers;
 }

 generateHeaders(method: string, url: string, contentType: string, accept?: string) {
  const authState = this.store.getState().auth;
  if (authState.isAuthenticated && authState.user.isAuthenticated) {
   return this.getAuthenticatedRequestHeaders(method, url, contentType, authState, accept);
  }
  // Uses generic auth headers, without user data.
  return generateRequestHeaders({
   url,
   method,
   accept,
   contentType,
   key: environment.apiKey,
   xDate: generateXDate(),
   username: '',
  });
 }

 private getAuthenticatedRequestHeaders(method: string, url: string, contentType: string, authState: AuthState, accept?: string): AuthRequestHeaders {
  const user = authState.user;
  if (!user.isAuthenticated) {
   throw new Error('User must be authenticated to generate authenticated request headers.');
  }
  return generateRequestHeaders({
   url,
   method,
   contentType,
   xDate: generateXDate(),
   key: user.token,
   username: user.username,
   accept,
  });
 }
}

