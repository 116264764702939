import { createContext, useContext } from 'react';
import { IClaims } from '@features/okta/types';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { UserRepository } from '@app/repositories/user.repository';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { AppRoute } from 'app/routesEnum';
import { completeLogin } from '@features/security/auth.actions';
import { FullStoryAPI } from 'react-fullstory';
import { useAction, AuthAction } from '@features/datadog';
import { publishLoginEvent } from 'app/repositories/analytics.repository';
import { useLoader } from '@app/hooks/use-loader.hook';
import { getUser, getMemberships, getOrganization } from 'api/userManagement';
import { setActiveMembership, setMemberships } from 'features/permissions';
import { User } from 'shared/models/user.model';
import { saveCarrier, saveUser } from 'features/security/auth.actions';
import { mapOrganizationToCarrierDetail, mapUserToUserModel, extractClaims, determineActiveMembership } from 'features/userAndCarrierData';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { useStoredCarrierDetail, useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import isEqual from 'lodash.isequal';
import { logOut } from '@features/okta/redux/oktaSlice';

export interface UserAndCarrierDataContextType {
  finishLogin: (arg0: boolean, arg1: boolean) => void;
  updateCarrierDetails: (organizationId: string) => void;
  updateUser: () => void;
  getUpdatedActiveMembership: () => Promise<Membership>;
}

export const UserAndCarrierDataContext = createContext<UserAndCarrierDataContextType>({
  finishLogin: null,
  updateCarrierDetails: null,
  updateUser: null,
  getUpdatedActiveMembership: null
});


export const UserAndCarrierDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const track = useAction();
  const loader = useLoader();
  const storedCarrierDetails = useStoredCarrierDetail();
  const storedUser = useStoredUser();

  const carrierRepo = new CarriersRepository(apiConfig.carrierAPI);
  const userRepo = new UserRepository(apiConfig.userAPI);

  const updateCarrierDetails = async (organizationId: string) => {
    const organization = await getOrganization(organizationId);
    const carrierDetails = mapOrganizationToCarrierDetail(organization.organization);

    if (!isEqual(storedCarrierDetails, carrierDetails)) {
      dispatch(saveCarrier(carrierDetails)); // only dispatch a save carrier action if the objects differ

      const { firstName, lastName, username, email, userId } = storedUser;

      // End the current FullStory session
      FullStoryAPI('identify', false);

      FullStoryAPI('event', 'UserAccessedOrganization', {
        companyCode: carrierDetails?.carrierCode
      });

      // Start a new FullStory session with the new carrier details
      FullStoryAPI('identify', userId.toString(), {
        displayName: `${firstName} ${lastName}`,
        companyName: carrierDetails?.name,
        carrierCode: carrierDetails?.carrierCode,
        userName: username,
        email: email,
      });
    }
  }


  const updateUser = async () => {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken || null;
    const claims = extractClaims(token);
    const getUserResponse = await getUser(claims?.userId);
    const userMemberships = await getMemberships(claims.userId);
    const activeMembership = determineActiveMembership(userMemberships);
    const user = mapUserToUserModel(getUserResponse?.user, claims, activeMembership);
    dispatch(saveUser(new User(user)));
  }

  const getUserAndCarrierData = async (claims: IClaims) => {
    const getUserData = await getUser(claims.userId);
    const activeMembership = await getUpdatedActiveMembership();
    let organization = null;

    organization = activeMembership ? await getOrganization(activeMembership.organizationId) : null;

    const user = mapUserToUserModel(getUserData.user, claims, activeMembership);
    const carrierDetails = mapOrganizationToCarrierDetail(organization?.organization);

    return { user, carrierDetails };
  }

  const getUpdatedActiveMembership = async () => {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken || null;
    const claims = extractClaims(token);
    const userMemberships = await getMemberships(claims?.userId);

    // for now logout user if no memberships are found
    if (!userMemberships) {
      dispatch(logOut());
    }

    dispatch(setMemberships(userMemberships));
    const activeMembership = determineActiveMembership(userMemberships);

    if (activeMembership) {
      dispatch(setActiveMembership(activeMembership));
      localStorage.setItem('active-membership', JSON.stringify(activeMembership));
      return activeMembership;
    } 

    return null;
  }

  const legacyGetUserAndCarrierData = async (claims: IClaims) => {
    const carrierCode = claims?.carrierCode;
    const user = await userRepo.getUser();
    const carrierDetailsJSON = carrierCode.toUpperCase().startsWith('V') ? 
      { continentCode: user.carrierRollups[0]?.regionCode, carrierCode: claims.carrierCode, rollups: [], divisions: [] } : // TODO: test with a vendor user
      await carrierRepo.getCarrier(carrierCode);
    carrierDetailsJSON.rollups = user.carrierRollups;
    carrierDetailsJSON.divisions = user.divisions;
    const carrierDetails = new CarrierDetail(carrierDetailsJSON);
    return { user, carrierDetails };
  }

  const finishLogin = async (enableUserManagement, findLoadsSendAnalyticsWeb) => {
    try {
      const token = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken || null;
      
      if (!token) {
        return;
      }

      const claims = extractClaims(token);

      // limited driver users can only access their accounts via the carrier app
      if (claims.carrierCode === 'TMPCARRIER') {
        dispatch(push(AppRoute.UNABLE_TO_SIGNIN_ON_WEB));
      }
      const getDataFromUMS = enableUserManagement || claims.carrierId === '0';
      const { user, carrierDetails } = getDataFromUMS ? await Promise.resolve(getUserAndCarrierData(claims)) : await Promise.resolve(legacyGetUserAndCarrierData(claims));

      dispatch(completeLogin(user, carrierDetails));

      if (findLoadsSendAnalyticsWeb) {
        publishLoginEvent(user, carrierDetails.carrierCode);
      }

      // finally, set DD Action of Successful Okta Login
      track(AuthAction.OktaLoginSuccess, user);

      // Update FullStory context
      const { firstName, lastName, username, emailAddress, userID } = user;
      const _userid = userID?.toString() || 'unknown';

      FullStoryAPI('event', 'UserAccessedOrganization', {
        companyCode: carrierDetails?.carrierCode
      });

      FullStoryAPI('identify', _userid, {
        displayName: `${firstName} ${lastName}`,
        companyName: carrierDetails?.name,
        carrierCode: carrierDetails?.carrierCode,
        userName: username,
        email: emailAddress,
      });
    } catch (error) {
      console.error(error);
      dispatch(logOut());
    } finally {
      loader.hide();
    }
  };

  const contextValues = {
    finishLogin,
    updateCarrierDetails,
    updateUser,
    getUpdatedActiveMembership
  }

  return <UserAndCarrierDataContext.Provider value={contextValues}>{children}</UserAndCarrierDataContext.Provider>;
}
export function useUserAndCarrierDataContext() {
  return useContext(UserAndCarrierDataContext);
}