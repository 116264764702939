export const TimeZones = [
  {
    'id': 'Dateline Standard Time',
    'name': '(UTC-12:00) International Date Line West',
    'offset': -720
  },
  {
    'id': 'UTC-11',
    'name': '(UTC-11:00) Coordinated Universal Time-11',
    'offset': -660
  },
  {
    'id': 'Aleutian Standard Time',
    'name': '(UTC-10:00) Aleutian Islands',
    'offset': -600
  },
  {
    'id': 'Hawaiian Standard Time',
    'name': '(UTC-10:00) Hawaii',
    'offset': -600
  },
  {
    'id': 'Marquesas Standard Time',
    'name': '(UTC-09:30) Marquesas Islands',
    'offset': -570
  },
  {
    'id': 'Alaskan Standard Time',
    'name': '(UTC-09:00) Alaska',
    'offset': -540
  },
  {
    'id': 'UTC-09',
    'name': '(UTC-09:00) Coordinated Universal Time-09',
    'offset': -540
  },
  {
    'id': 'Pacific Standard Time (Mexico)',
    'name': '(UTC-08:00) Baja California',
    'offset': -480
  },
  {
    'id': 'UTC-08',
    'name': '(UTC-08:00) Coordinated Universal Time-08',
    'offset': -480
  },
  {
    'id': 'Pacific Standard Time',
    'name': '(UTC-08:00) Pacific Time (US & Canada)',
    'offset': -480
  },
  {
    'id': 'US Mountain Standard Time',
    'name': '(UTC-07:00) Arizona',
    'offset': -420
  },
  {
    'id': 'Mountain Standard Time (Mexico)',
    'name': '(UTC-07:00) La Paz, Mazatlan',
    'offset': -420
  },
  {
    'id': 'Mountain Standard Time',
    'name': '(UTC-07:00) Mountain Time (US & Canada)',
    'offset': -420
  },
  {
    'id': 'Yukon Standard Time',
    'name': '(UTC-07:00) Yukon',
    'offset': -420
  },
  {
    'id': 'Central America Standard Time',
    'name': '(UTC-06:00) Central America',
    'offset': -360
  },
  {
    'id': 'Central Standard Time',
    'name': '(UTC-06:00) Central Time (US & Canada)',
    'offset': -360
  },
  {
    'id': 'Easter Island Standard Time',
    'name': '(UTC-06:00) Easter Island',
    'offset': -360
  },
  {
    'id': 'Central Standard Time (Mexico)',
    'name': '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    'offset': -360
  },
  {
    'id': 'Canada Central Standard Time',
    'name': '(UTC-06:00) Saskatchewan',
    'offset': -360
  },
  {
    'id': 'SA Pacific Standard Time',
    'name': '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    'offset': -300
  },
  {
    'id': 'Eastern Standard Time (Mexico)',
    'name': '(UTC-05:00) Chetumal',
    'offset': -300
  },
  {
    'id': 'Eastern Standard Time',
    'name': '(UTC-05:00) Eastern Time (US & Canada)',
    'offset': -300
  },
  {
    'id': 'Haiti Standard Time',
    'name': '(UTC-05:00) Haiti',
    'offset': -300
  },
  {
    'id': 'Cuba Standard Time',
    'name': '(UTC-05:00) Havana',
    'offset': -300
  },
  {
    'id': 'US Eastern Standard Time',
    'name': '(UTC-05:00) Indiana (East)',
    'offset': -300
  },
  {
    'id': 'Turks And Caicos Standard Time',
    'name': '(UTC-05:00) Turks and Caicos',
    'offset': -300
  },
  {
    'id': 'Atlantic Standard Time',
    'name': '(UTC-04:00) Atlantic Time (Canada)',
    'offset': -240
  },
  {
    'id': 'Venezuela Standard Time',
    'name': '(UTC-04:00) Caracas',
    'offset': -240
  },
  {
    'id': 'Central Brazilian Standard Time',
    'name': '(UTC-04:00) Cuiaba',
    'offset': -240
  },
  {
    'id': 'SA Western Standard Time',
    'name': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    'offset': -240
  },
  {
    'id': 'Pacific SA Standard Time',
    'name': '(UTC-04:00) Santiago',
    'offset': -240
  },
  {
    'id': 'Newfoundland Standard Time',
    'name': '(UTC-03:30) Newfoundland',
    'offset': -210
  },
  {
    'id': 'Tocantins Standard Time',
    'name': '(UTC-03:00) Araguaina',
    'offset': -180
  },
  {
    'id': 'Paraguay Standard Time',
    'name': '(UTC-03:00) Asuncion',
    'offset': -180
  },
  {
    'id': 'E. South America Standard Time',
    'name': '(UTC-03:00) Brasilia',
    'offset': -180
  },
  {
    'id': 'SA Eastern Standard Time',
    'name': '(UTC-03:00) Cayenne, Fortaleza',
    'offset': -180
  },
  {
    'id': 'Argentina Standard Time',
    'name': '(UTC-03:00) City of Buenos Aires',
    'offset': -180
  },
  {
    'id': 'Montevideo Standard Time',
    'name': '(UTC-03:00) Montevideo',
    'offset': -180
  },
  {
    'id': 'Magallanes Standard Time',
    'name': '(UTC-03:00) Punta Arenas',
    'offset': -180
  },
  {
    'id': 'Saint Pierre Standard Time',
    'name': '(UTC-03:00) Saint Pierre and Miquelon',
    'offset': -180
  },
  {
    'id': 'Bahia Standard Time',
    'name': '(UTC-03:00) Salvador',
    'offset': -180
  },
  {
    'id': 'UTC-02',
    'name': '(UTC-02:00) Coordinated Universal Time-02',
    'offset': -120
  },
  {
    'id': 'Greenland Standard Time',
    'name': '(UTC-02:00) Greenland',
    'offset': -120
  },
  {
    'id': 'Mid-Atlantic Standard Time',
    'name': '(UTC-02:00) Mid-Atlantic - Old',
    'offset': -120
  },
  {
    'id': 'Azores Standard Time',
    'name': '(UTC-01:00) Azores',
    'offset': -60
  },
  {
    'id': 'Cape Verde Standard Time',
    'name': '(UTC-01:00) Cabo Verde Is.',
    'offset': -60
  },
  {
    'id': 'UTC',
    'name': '(UTC) Coordinated Universal Time',
    'offset': 0
  },
  {
    'id': 'GMT Standard Time',
    'name': '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    'offset': 0
  },
  {
    'id': 'Greenwich Standard Time',
    'name': '(UTC+00:00) Monrovia, Reykjavik',
    'offset': 0
  },
  {
    'id': 'Sao Tome Standard Time',
    'name': '(UTC+00:00) Sao Tome',
    'offset': 0
  },
  {
    'id': 'Morocco Standard Time',
    'name': '(UTC+01:00) Casablanca',
    'offset': 0
  },
  {
    'id': 'W. Europe Standard Time',
    'name': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    'offset': 60
  },
  {
    'id': 'Central Europe Standard Time',
    'name': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    'offset': 60
  },
  {
    'id': 'Romance Standard Time',
    'name': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    'offset': 60
  },
  {
    'id': 'Central European Standard Time',
    'name': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    'offset': 60
  },
  {
    'id': 'W. Central Africa Standard Time',
    'name': '(UTC+01:00) West Central Africa',
    'offset': 60
  },
  {
    'id': 'GTB Standard Time',
    'name': '(UTC+02:00) Athens, Bucharest',
    'offset': 120
  },
  {
    'id': 'Middle East Standard Time',
    'name': '(UTC+02:00) Beirut',
    'offset': 120
  },
  {
    'id': 'Egypt Standard Time',
    'name': '(UTC+02:00) Cairo',
    'offset': 120
  },
  {
    'id': 'E. Europe Standard Time',
    'name': '(UTC+02:00) Chisinau',
    'offset': 120
  },
  {
    'id': 'West Bank Standard Time',
    'name': '(UTC+02:00) Gaza, Hebron',
    'offset': 120
  },
  {
    'id': 'South Africa Standard Time',
    'name': '(UTC+02:00) Harare, Pretoria',
    'offset': 120
  },
  {
    'id': 'FLE Standard Time',
    'name': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    'offset': 120
  },
  {
    'id': 'Israel Standard Time',
    'name': '(UTC+02:00) Jerusalem',
    'offset': 120
  },
  {
    'id': 'South Sudan Standard Time',
    'name': '(UTC+02:00) Juba',
    'offset': 120
  },
  {
    'id': 'Kaliningrad Standard Time',
    'name': '(UTC+02:00) Kaliningrad',
    'offset': 120
  },
  {
    'id': 'Sudan Standard Time',
    'name': '(UTC+02:00) Khartoum',
    'offset': 120
  },
  {
    'id': 'Libya Standard Time',
    'name': '(UTC+02:00) Tripoli',
    'offset': 120
  },
  {
    'id': 'Namibia Standard Time',
    'name': '(UTC+02:00) Windhoek',
    'offset': 120
  },
  {
    'id': 'Jordan Standard Time',
    'name': '(UTC+03:00) Amman',
    'offset': 180
  },
  {
    'id': 'Arabic Standard Time',
    'name': '(UTC+03:00) Baghdad',
    'offset': 180
  },
  {
    'id': 'Syria Standard Time',
    'name': '(UTC+03:00) Damascus',
    'offset': 180
  },
  {
    'id': 'Turkey Standard Time',
    'name': '(UTC+03:00) Istanbul',
    'offset': 180
  },
  {
    'id': 'Arab Standard Time',
    'name': '(UTC+03:00) Kuwait, Riyadh',
    'offset': 180
  },
  {
    'id': 'Belarus Standard Time',
    'name': '(UTC+03:00) Minsk',
    'offset': 180
  },
  {
    'id': 'Russian Standard Time',
    'name': '(UTC+03:00) Moscow, St. Petersburg',
    'offset': 180
  },
  {
    'id': 'E. Africa Standard Time',
    'name': '(UTC+03:00) Nairobi',
    'offset': 180
  },
  {
    'id': 'Volgograd Standard Time',
    'name': '(UTC+03:00) Volgograd',
    'offset': 180
  },
  {
    'id': 'Iran Standard Time',
    'name': '(UTC+03:30) Tehran',
    'offset': 210
  },
  {
    'id': 'Arabian Standard Time',
    'name': '(UTC+04:00) Abu Dhabi, Muscat',
    'offset': 240
  },
  {
    'id': 'Astrakhan Standard Time',
    'name': '(UTC+04:00) Astrakhan, Ulyanovsk',
    'offset': 240
  },
  {
    'id': 'Azerbaijan Standard Time',
    'name': '(UTC+04:00) Baku',
    'offset': 240
  },
  {
    'id': 'Russia Time Zone 3',
    'name': '(UTC+04:00) Izhevsk, Samara',
    'offset': 240
  },
  {
    'id': 'Mauritius Standard Time',
    'name': '(UTC+04:00) Port Louis',
    'offset': 240
  },
  {
    'id': 'Saratov Standard Time',
    'name': '(UTC+04:00) Saratov',
    'offset': 240
  },
  {
    'id': 'Georgian Standard Time',
    'name': '(UTC+04:00) Tbilisi',
    'offset': 240
  },
  {
    'id': 'Caucasus Standard Time',
    'name': '(UTC+04:00) Yerevan',
    'offset': 240
  },
  {
    'id': 'Afghanistan Standard Time',
    'name': '(UTC+04:30) Kabul',
    'offset': 270
  },
  {
    'id': 'West Asia Standard Time',
    'name': '(UTC+05:00) Ashgabat, Tashkent',
    'offset': 300
  },
  {
    'id': 'Qyzylorda Standard Time',
    'name': '(UTC+05:00) Astana',
    'offset': 300
  },
  {
    'id': 'Ekaterinburg Standard Time',
    'name': '(UTC+05:00) Ekaterinburg',
    'offset': 300
  },
  {
    'id': 'Pakistan Standard Time',
    'name': '(UTC+05:00) Islamabad, Karachi',
    'offset': 300
  },
  {
    'id': 'India Standard Time',
    'name': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    'offset': 330
  },
  {
    'id': 'Sri Lanka Standard Time',
    'name': '(UTC+05:30) Sri Jayawardenepura',
    'offset': 330
  },
  {
    'id': 'Nepal Standard Time',
    'name': '(UTC+05:45) Kathmandu',
    'offset': 345
  },
  {
    'id': 'Central Asia Standard Time',
    'name': '(UTC+06:00) Bishkek',
    'offset': 360
  },
  {
    'id': 'Bangladesh Standard Time',
    'name': '(UTC+06:00) Dhaka',
    'offset': 360
  },
  {
    'id': 'Omsk Standard Time',
    'name': '(UTC+06:00) Omsk',
    'offset': 360
  },
  {
    'id': 'Myanmar Standard Time',
    'name': '(UTC+06:30) Yangon (Rangoon)',
    'offset': 390
  },
  {
    'id': 'SE Asia Standard Time',
    'name': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    'offset': 420
  },
  {
    'id': 'Altai Standard Time',
    'name': '(UTC+07:00) Barnaul, Gorno-Altaysk',
    'offset': 420
  },
  {
    'id': 'W. Mongolia Standard Time',
    'name': '(UTC+07:00) Hovd',
    'offset': 420
  },
  {
    'id': 'North Asia Standard Time',
    'name': '(UTC+07:00) Krasnoyarsk',
    'offset': 420
  },
  {
    'id': 'N. Central Asia Standard Time',
    'name': '(UTC+07:00) Novosibirsk',
    'offset': 420
  },
  {
    'id': 'Tomsk Standard Time',
    'name': '(UTC+07:00) Tomsk',
    'offset': 420
  },
  {
    'id': 'China Standard Time',
    'name': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    'offset': 480
  },
  {
    'id': 'North Asia East Standard Time',
    'name': '(UTC+08:00) Irkutsk',
    'offset': 480
  },
  {
    'id': 'Singapore Standard Time',
    'name': '(UTC+08:00) Kuala Lumpur, Singapore',
    'offset': 480
  },
  {
    'id': 'W. Australia Standard Time',
    'name': '(UTC+08:00) Perth',
    'offset': 480
  },
  {
    'id': 'Taipei Standard Time',
    'name': '(UTC+08:00) Taipei',
    'offset': 480
  },
  {
    'id': 'Ulaanbaatar Standard Time',
    'name': '(UTC+08:00) Ulaanbaatar',
    'offset': 480
  },
  {
    'id': 'Aus Central W. Standard Time',
    'name': '(UTC+08:45) Eucla',
    'offset': 525
  },
  {
    'id': 'Transbaikal Standard Time',
    'name': '(UTC+09:00) Chita',
    'offset': 540
  },
  {
    'id': 'Tokyo Standard Time',
    'name': '(UTC+09:00) Osaka, Sapporo, Tokyo',
    'offset': 540
  },
  {
    'id': 'North Korea Standard Time',
    'name': '(UTC+09:00) Pyongyang',
    'offset': 540
  },
  {
    'id': 'Korea Standard Time',
    'name': '(UTC+09:00) Seoul',
    'offset': 540
  },
  {
    'id': 'Yakutsk Standard Time',
    'name': '(UTC+09:00) Yakutsk',
    'offset': 540
  },
  {
    'id': 'Cen. Australia Standard Time',
    'name': '(UTC+09:30) Adelaide',
    'offset': 570
  },
  {
    'id': 'AUS Central Standard Time',
    'name': '(UTC+09:30) Darwin',
    'offset': 570
  },
  {
    'id': 'E. Australia Standard Time',
    'name': '(UTC+10:00) Brisbane',
    'offset': 600
  },
  {
    'id': 'AUS Eastern Standard Time',
    'name': '(UTC+10:00) Canberra, Melbourne, Sydney',
    'offset': 600
  },
  {
    'id': 'West Pacific Standard Time',
    'name': '(UTC+10:00) Guam, Port Moresby',
    'offset': 600
  },
  {
    'id': 'Tasmania Standard Time',
    'name': '(UTC+10:00) Hobart',
    'offset': 600
  },
  {
    'id': 'Vladivostok Standard Time',
    'name': '(UTC+10:00) Vladivostok',
    'offset': 600
  },
  {
    'id': 'Lord Howe Standard Time',
    'name': '(UTC+10:30) Lord Howe Island',
    'offset': 630
  },
  {
    'id': 'Bougainville Standard Time',
    'name': '(UTC+11:00) Bougainville Island',
    'offset': 660
  },
  {
    'id': 'Russia Time Zone 10',
    'name': '(UTC+11:00) Chokurdakh',
    'offset': 660
  },
  {
    'id': 'Magadan Standard Time',
    'name': '(UTC+11:00) Magadan',
    'offset': 660
  },
  {
    'id': 'Norfolk Standard Time',
    'name': '(UTC+11:00) Norfolk Island',
    'offset': 660
  },
  {
    'id': 'Sakhalin Standard Time',
    'name': '(UTC+11:00) Sakhalin',
    'offset': 660
  },
  {
    'id': 'Central Pacific Standard Time',
    'name': '(UTC+11:00) Solomon Is., New Caledonia',
    'offset': 660
  },
  {
    'id': 'Russia Time Zone 11',
    'name': '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    'offset': 720
  },
  {
    'id': 'New Zealand Standard Time',
    'name': '(UTC+12:00) Auckland, Wellington',
    'offset': 720
  },
  {
    'id': 'UTC+12',
    'name': '(UTC+12:00) Coordinated Universal Time+12',
    'offset': 720
  },
  {
    'id': 'Fiji Standard Time',
    'name': '(UTC+12:00) Fiji',
    'offset': 720
  },
  {
    'id': 'Kamchatka Standard Time',
    'name': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    'offset': 720
  },
  {
    'id': 'Chatham Islands Standard Time',
    'name': '(UTC+12:45) Chatham Islands',
    'offset': 765
  },
  {
    'id': 'UTC+13',
    'name': '(UTC+13:00) Coordinated Universal Time+13',
    'offset': 780
  },
  {
    'id': 'Tonga Standard Time',
    'name': '(UTC+13:00) Nuku\'alofa',
    'offset': 780
  },
  {
    'id': 'Samoa Standard Time',
    'name': '(UTC+13:00) Samoa',
    'offset': 780
  },
  {
    'id': 'Line Islands Standard Time',
    'name': '(UTC+14:00) Kiritimati Island',
    'offset': 840
  }
];
