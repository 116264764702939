import { AnyAction } from 'redux';
import { Cookie } from 'ng2-cookies';
import { ActionsObservable, combineEpics } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';

import * as a from '@features/security/auth.actions';
import { hideModal, HideModalAction, showModal, ShowModalAction } from 'shared/components/modal/modal.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { initCulture } from 'app/i18n/culture.actions';

const HAZMAT_STATUS_CONFIRMED = 'hazmat-status-confirmed';

export const verifyHazmatEpic: NavCarrierEpic<AnyAction> = action$ =>
  action$.ofType<a.CombinedUserDataAction>(a.VERIFY_HAZMAT_STATUS)
    .filter(() => !Boolean(Cookie.get(HAZMAT_STATUS_CONFIRMED)))
    .mergeMap(({ userJSON, carrierDetail }) =>
      (carrierDetail?.hasExpiringHazmat() || carrierDetail?.hasExpiredHazmat())
        ? ActionsObservable.of(initCulture(), a.displayHazmatConfirmation(userJSON, carrierDetail))
        : ActionsObservable.of(a.verifyTermsAndConditionsStatus(userJSON, carrierDetail))
    );

export const skipHazmatConfirmationEpic: NavCarrierEpic<AnyAction> = action$ =>
  action$.ofType<a.CombinedUserDataAction>(a.FETCH_CARRIER_DETAIL_FOR_USER_SUCCESS)
    .filter(() => Boolean(Cookie.get(HAZMAT_STATUS_CONFIRMED)))
    .mergeMap(({ userJSON, carrierDetail }) => ActionsObservable.of(initCulture(), a.verifyTermsAndConditionsStatus(userJSON, carrierDetail)));

export const displayHazmatConfirmationModalEpic: NavCarrierEpic<ShowModalAction> = action$ =>
  action$.ofType(a.DISPLAY_HAZMAT_CONFIRMATION)
    .mapTo(showModal('hazmat-expired'));

export const confirmHazmatStatusEpic: NavCarrierEpic<a.CombinedUserDataAction> = (action$, state$) =>
  action$.ofType(a.CONFIRM_HAZMAT_STATUS)
    .map(() => state$.value.auth.pending)
    .map(({ userJSON, carrier }) => a.verifyTermsAndConditionsStatus(userJSON, carrier));

export const closeHazmatConfirmationModalEpic: NavCarrierEpic<HideModalAction> = action$ =>
  action$.ofType(a.CONFIRM_HAZMAT_STATUS)
    .mapTo(hideModal('hazmat-expired'));

export const setHazmatConfirmedCookie: NavCarrierEpic<HideModalAction> = action$ =>
  action$.ofType(a.CONFIRM_HAZMAT_STATUS)
    .do(() => Cookie.set(HAZMAT_STATUS_CONFIRMED, new Date().toUTCString(), 1))
    .ignoreElements();

export const hazmatEpics = combineEpics(
  verifyHazmatEpic,
  skipHazmatConfirmationEpic,
  displayHazmatConfirmationModalEpic,
  confirmHazmatStatusEpic,
  closeHazmatConfirmationModalEpic,
  setHazmatConfirmedCookie
);
