import { CashAdvanceSourceType } from 'shared/models/cash-advances/cash-advance-options-source.model';
import { CashAdvanceOptions } from 'shared/models/cash-advances/cash-advance-options.model';
import { CashAdvance } from 'shared/models/cash-advances/cash-advance.model';
import { Reducer } from 'redux';
import { CashAdvanceEventsAction } from '../analytics-v2.actions';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';

export interface CashAdvanceEventsState {
  cashAdvanceRequested: boolean;
  cashAdvanceSubmitted: boolean;
  cashAdvanceContinued: boolean;
  cashAdvanceReviewContinued: boolean;
  cashAdvanceSuccess: boolean;
  cashAdvanceError: boolean;
  cashAdvanceOptions: CashAdvanceOptions;
  load: ExtendedLoadInterface;
  requestedAmount: number;
  type: CashAdvanceSourceType | string;
  transactionFee: number;
  responseCode: number;
  cashAdvance: CashAdvance;
  passcodeOutcome: boolean;
  cashAdvanceViewSuccess: boolean;
  cashAdvanceViewError: boolean;
}

export const initialState: CashAdvanceEventsState = {
  cashAdvanceRequested: false,
  cashAdvanceSubmitted: false,
  cashAdvanceContinued: false,
  cashAdvanceReviewContinued: false,
  cashAdvanceSuccess: false,
  cashAdvanceError: false,
  cashAdvanceOptions: null,
  load: null,
  requestedAmount: null,
  type: null,
  transactionFee: null,
  responseCode: null,
  cashAdvance: null,
  passcodeOutcome: null,
  cashAdvanceViewSuccess: null,
  cashAdvanceViewError: null,
};

export const cashAdvanceEvents: Reducer = (state: CashAdvanceEventsState = initialState, action: CashAdvanceEventsAction): CashAdvanceEventsState => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_CASH_ADVANCE_RESET:
      return {
        ...state,
        cashAdvanceSuccess: false,
        cashAdvanceError: false,
        cashAdvanceSubmitted: false,
        cashAdvanceRequested: false,
        cashAdvanceContinued: false,
        cashAdvanceReviewContinued: false,
        responseCode: null,
        passcodeOutcome: null,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_LOAD:
      return {
        ...state,
        load: action.payload.load,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_OPTIONS:
      return {
        ...state,
        cashAdvanceOptions: action.payload.cashAdvanceOptions,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_CONTINUE_EVENT:
      return {
        ...state,
        cashAdvanceContinued: true,
        requestedAmount: action.payload.requestedAmount,
        type: action.payload.type,
        transactionFee: action.payload.transactionFee,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_REVIEW_CONTINUE_EVENT:
      return {
        ...state,
        cashAdvanceReviewContinued: true,
        requestedAmount: action.payload.requestedAmount,
        type: action.payload.type,
        transactionFee: action.payload.transactionFee,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_SUBMIT_EVENT:
      return {
        ...state,
        cashAdvanceSubmitted: true,
        cashAdvanceSuccess: false,
        cashAdvanceError: false,
        requestedAmount: action.payload.requestedAmount,
        type: action.payload.type,
        transactionFee: action.payload.transactionFee,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_SUCCESS_EVENT:
      return {
        ...state,
        cashAdvance: action.payload.cashAdvance,
        cashAdvanceSubmitted: false,
        cashAdvanceSuccess: true,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_ERROR_EVENT:
      return {
        ...state,
        cashAdvanceSubmitted: false,
        cashAdvanceError: true,
        responseCode: action.payload.responseCode,
        passcodeOutcome: action.payload.passcodeOutcome,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_REQUEST_EVENT:
      return {
        ...state,
        cashAdvanceRequested: true,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_VIEW_RESET:
      return {
        ...state,
        cashAdvanceViewSuccess: false,
        cashAdvanceViewError: false,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_VIEW_SUCCESS_EVENT:
      return {
        ...state,
        cashAdvance: action.payload.cashAdvance,
        cashAdvanceViewSuccess: true,
      };
    case AnalyticsActionTypes.SET_CASH_ADVANCE_VIEW_ERROR_EVENT:
      return {
        ...state,
        cashAdvanceViewError: true,
        responseCode: action.payload.responseCode,
        passcodeOutcome: action.payload.passcodeOutcome,
      };
    default:
      return state;
  }
};
