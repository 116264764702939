import moment from 'moment';
import { Carrier } from 'shared/models/carrier';
import { Roles } from './roles.model';
import { TimeFormat } from 'shared/enums/time-format.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { TimeZones } from 'shared/data/misc/timezones.data';

export class User {
  contactId: number;
  isDriver?: boolean;
  userId?: number;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  carrierRollups?: Carrier[]; 
  divisions?: number[];
  properties?: {
    country?: string;
    culture?: string;
    startPage?: string;
    timeFormat?: TimeFormat;
    timeZone?: TimeZone;
    unitOfMeasure?: UnitOfMeasure;
    disableLoadCheckinUpdate?: boolean;
    disableLoadDropUpdate?: boolean;
    disableLoadPickUpdate?: boolean;
    preferredCurrencyCode?: string;
    hasVerifiedPhone?: boolean;
  };
  username?: string;
  permissions?: number[];
  roles?: Roles
  readonly isAuthenticated;
  newMessageCount = 0;
  acceptedAgreement: boolean;

  constructor(json: UserJSON) {
    this.contactId = json.contactId;
    this.email = json.emailAddress;
    this.phone = json.phoneNumber;
    this.isDriver = json.isDriver;
    this.userId = json.userID;
    this.username = json.username;
    this.firstName = json.firstName;
    this.lastName = json.lastName;

    this.properties = {};

    this.carrierRollups = []; // TODO UM Release: remove carrierRollups from user

    if (Array.isArray(json.carrierRollups)) {
      this.carrierRollups = json.carrierRollups.map(carrierJSON => new Carrier(carrierJSON));
    }

    if (json.properties) {
      this.properties = {
        timeFormat: (Number(json.properties.timeFormat) === 12 ? TimeFormat.TwelveHour : TimeFormat.TwentyFourHour),
        country: json.properties.country,
        culture: json.properties.culture,
        startPage: json.properties.startPage,
        disableLoadCheckinUpdate: json.properties.disableLoadCheckinUpdate === 'True',
        disableLoadDropUpdate: json.properties.disableLoadDropUpdate === 'True',
        disableLoadPickUpdate: json.properties.disableLoadPickUpdate === 'True',
        preferredCurrencyCode: json.properties.preferredCurrencyCode,
        hasVerifiedPhone: json.properties.hasVerifiedPhone
      };
      if (json.properties.unitOfMeasure) {
        this.properties.unitOfMeasure = Number(json.properties.unitOfMeasure);
      }
      if (this.properties.culture) {
        moment.locale(this.properties.culture);
      }
      if (json.properties.timeZone) {
        this.properties.timeZone = { ...TimeZones.find(x => x.id === json.properties.timeZone) }
      }
    }

    this.permissions = json.permissions;
    this.acceptedAgreement = json.acceptedAgreement;

    this.isAuthenticated = Boolean(json.username);

    if (Array.isArray(json.divisions)) {
      this.divisions = json.divisions;
    } else {
      this.divisions = [];
    }

    if (Array.isArray(json.permissions)) {
      this.roles = new Roles(json.permissions);
    } else {
      this.roles = new Roles([]);
    }
  }

  public toJson(): UserJSON {
    return {
      contactId: this.contactId,
      emailAddress: this.email,
      phoneNumber: this.phone,
      isDriver: this.isDriver,
      userID: this.userId,
      username: this.username,
      firstName: this.firstName,
      lastName: this.lastName,
      properties: {
        ...this.properties,
        timeFormat: this.properties.timeFormat != null ? this.properties.timeFormat.toString() : undefined,
        unitOfMeasure: this.properties.unitOfMeasure != null ? this.properties.unitOfMeasure.toString() : undefined,
        timeZone: this.properties.timeZone != null ? this.properties.timeZone.id : undefined,
        disableLoadPickUpdate: this.properties.disableLoadPickUpdate ? 'True' : 'False',
        disableLoadDropUpdate: this.properties.disableLoadDropUpdate ? 'True' : 'False',
        disableLoadCheckinUpdate: this.properties.disableLoadCheckinUpdate ? 'True' : 'False',
        preferredCurrencyCode: this.properties != null ? this.properties.preferredCurrencyCode : undefined
      },
      permissions: this.permissions,
      acceptedAgreement: this.acceptedAgreement
    };
  }

  public getDisplayName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public getStartPageKey() {
    return this.properties?.startPage;
  }

  getUnitOfMeasure(): UnitOfMeasure {
    return this.properties?.unitOfMeasure;
  }

  isMetric(): boolean {
    return this.getUnitOfMeasure() === UnitOfMeasure.Metric;
  }

  public is12Hour() {
    return this.properties.timeFormat === TimeFormat.TwelveHour;
  }

  getTimeZone(): string {
    return this.properties.timeZone ? this.properties.timeZone.id : undefined;
  }

  getPreferredCurrencyCode(): string {
    return this.properties?.preferredCurrencyCode;
  }
}
