import { Inject, Service } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Contact } from 'shared/models/contacts/contact.model';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { Truck } from 'shared/models/truck.model';
import { Carrier } from 'shared/models/carrier/carrier.model';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { setDateToMidnight, Util } from 'app/util/util';
import { convertUOMFromTrex, convertUOMToTrex } from 'providers/unit-of-measure.provider';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { SalesRep } from 'shared/models/contacts/sales-rep.model';
import { CarrierCapAdvantageScores } from 'shared/models/cap/carrier-cap-advantage-scores';
import { CapScoreEvents } from 'shared/models/cap/cap-score-events.model';
import { CapDetailsV2 } from 'shared/models/cap/cap-details-v2.model';

@Service()
export class CarriersRepository extends CacheableRepository {

  protected baseUrl: string;

  constructor(url?: string) {
    super();
    this.baseUrl = url || apiConfig.carrierAPI;
  }

  public getDriverContacts(carrierCode: string, forceRefresh?: boolean): Observable<Contact[]> {
    // Do not load additional fields. This enables retrieval of the IsAppUser property which is unused in the website.
    // This causes unnecessary SQL calls.
    const loadAdditionalFields = false;

    return this.getWithCache(`/Carrier/${carrierCode}/Drivers?loadAdditionalFields=${loadAdditionalFields}`, forceRefresh).map(contacts =>
      contacts
        .map(json => new Contact(json))
        .sort(Util.sortByField<Contact>('lastName'))
        .sort(Util.sortByField<Contact>('firstName'))
    );
  }

  public getSalesReps(carrier: CarrierDetail, leadOnly = false): Observable<SalesRep[]> {
    return this.get(`/Carrier/${carrier.carrierCode}/SalesReps?leadOnly=${leadOnly}`).map(salesReps =>
      salesReps.map((json: SalesRepJSON) => new SalesRep(json))
    );
  }

  public getContact(carrierCode: string, contactId: number): Observable<Contact> {
    return this.get(`/Carrier/${carrierCode}/Contact/${contactId}`).map(json => new Contact(json));
  }

  // A driver is a contact
  public createContact(carrierCode: string, contact: Contact): Observable<Contact> {
    const contactUrl = `/Carrier/${carrierCode}/Contact`;

    contact.id = null;
    contact.contactSequenceNumber = null;

    return this.post(contactUrl, JSON.stringify(contact.toJson())).map(json => new Contact(json));
  }

  public updateContact(carrierCode: string, contact: Contact): Observable<Contact> {
    const contactUrl = `/Carrier/${carrierCode}/Contact`;
    return this.put(contactUrl, JSON.stringify(contact.toJson())).map(json => new Contact(json));
  }

  public deleteContact(carrierCode: string, contact: Contact): Observable<Contact> {
    return this.delete(`/Carrier/${carrierCode}/Contact/${contact.id}`);
  }

  public postTruck(truck: Truck[], carrierCode: string) {
    const todayAtMidnight = setDateToMidnight(new Date());

    // filter expired, convert to JSON, convert UOM
    const truckJSON = truck
      .filter(eq => eq.emptyDateTime >= todayAtMidnight)
      .map(response => response.toJson())
      .map(json => this.convertUOMForAPI(json));

    return this.post(`/Carriers/${carrierCode}/Trucks`, truckJSON);
  }

  public getPostedTrucks(carrier: CarrierDetail) {
    const carrierCodes = carrier.rollups.map(carrier => `carrierCodes=${carrier?.code}`);

    return this.get(`/Trucks?${carrierCodes.join('&')}`)
      .map(trucksJSON => trucksJSON.map(truck => this.convertUOMForSite(truck)))
      .map(trucksJSON => trucksJSON.map(truck => new Truck(truck)))
      .map(trucks => trucks.sort((a, b) => a.id - b.id));
  }

  public deletePostedTruck(carrierCode: string, truck: Truck) {
    return this.delete(`/Carriers/${carrierCode}/Trucks/${truck.id}`);
  }


  // noinspection JSMethodCanBeStatic
  private convertUOMForAPI(truckJSON: TruckJSON): TruckJSON {
    return {
      ...truckJSON,
      sourceUOM: convertUOMToTrex(truckJSON.sourceUOM),
    };
  }

  // noinspection JSMethodCanBeStatic
  private convertUOMForSite(truckJSON: TruckJSON): TruckJSON {
    return {
      ...truckJSON,
      sourceUOM: convertUOMFromTrex(truckJSON.sourceUOM),
    };
  }

  /** INSURANCE **/

  public getCarrier(carrierCode: string): Promise<CarrierDetailJSON> {
    return this.get(`/Carrier/${carrierCode}`)
      .map(response => response)
      .catch((error: AjaxError) => Observable.throw(new APIErrorResponse(error.response, error.status)))
      .toPromise();
  }
}
