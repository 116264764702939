import { AvailableLoadSummary } from '@shared/models/loads/load-summaries/available-load-summary.model';
import { publishEvent } from 'api/analytics'
import { User } from 'shared/models/user.model';

export async function publishLoginEvent(user: UserJSON, carrierCode: string) {
  const navCarrierData: NavCarrierData = {
    carrierCode: carrierCode
  };
  await publishEvent('Login', navCarrierData, user.userID.toString(), user.emailAddress, null, user);
}

export async function publishSearchSubmittedEvent(searchType: SearchType, searchCorrelationId: string, searchCriteria: AvailableLoadSearchCriteriaJSON, user: User, carrierCode: string) {
  const navCarrierData: NavCarrierData = {
    carrierCode: carrierCode,
    searchCorrelationID: searchCorrelationId
  };

  let eventData: SearchSubmitted = {
    searchType: searchType
  };

  if (searchCriteria) {
    eventData = {
      ...eventData,
      loadNumber: searchCriteria.loadNumber?.toString(),
      originLatitude: searchCriteria.originLatitude,
      originLongitude: searchCriteria.originLongitude,
      originCountryCode: searchCriteria.originCountryCode,
      originStateProvince: searchCriteria.originStateProvinceCode,
      originCity: searchCriteria.originCity,
      originRadiusMiles: searchCriteria.originRadiusMiles,
      destinationLatitude: searchCriteria.destinationLatitude,
      destinationLongitude: searchCriteria.destinationLongitude,
      destinationCountryCode: searchCriteria.destinationCountryCode,
      destinationStateProvince: searchCriteria.destinationStateProvinceCode,
      destinationCity: searchCriteria.destinationCity,
      destinationRadiusMiles: searchCriteria.destinationRadiusMiles,
      pickupStartDate: searchCriteria.pickupStart,
      pickupEndDate: searchCriteria.pickupEnd,
      mileMin: NumberNormalize(searchCriteria.milesMin),
      mileMax: NumberNormalize(searchCriteria.milesMax),
      equipmentMode: StringNormalize(searchCriteria.mode),
      specializedEquipmentCode: searchCriteria.specializedEquipmentCode,
      equipmentLengthMax: NumberNormalize(searchCriteria.equipmentLengthMax),
      weightMin: NumberNormalize(searchCriteria.weightMin),
      weightMax: NumberNormalize(searchCriteria.weightMax),
      isStfLoad: searchCriteria.stfLoad,
      teamLoad: searchCriteria.teamLoad,
      carrierTierAvailable: searchCriteria.carrierTierAvailable,
      webExclusive: searchCriteria.webExclusive,
      originExampleZipCode: searchCriteria.originExampleZipCode,
      destinationExampleZipCode: searchCriteria.destinationExampleZipCode,
      pageSize: searchCriteria.pageSize
    };
  }

  await publishEvent('SearchSubmitted', navCarrierData, user.userId.toString(), user.email, eventData);
}

export async function publishLoadClickedEvent(load: AvailableLoadSummary, pageNumber: number, pageSize: number, searchCorrelationId: string, user: User, carrierCode: string, autoSelected: boolean = false) {
  const eventType = autoSelected ? 'LoadAutoSelected' : 'LoadClicked';
  const navCarrierData: NavCarrierData = {
    carrierCode: carrierCode,
    searchCorrelationID: searchCorrelationId
  };

  const eventData: LoadSelected = {
    loadNumber: load.number.toString(),
    binPrice: load.binRateCost?.totalCost ?? null,
    binCurrency: load.binRateCost?.currencyCode ?? null,
    loadPageNumber: pageNumber,
    loadPageSize: pageSize
  };

  await publishEvent(eventType, navCarrierData, user.userId.toString(), user.email, eventData);
}

export async function publishBookActionEvent(bookActionType: BookActionType, loadNumber: string, bookPrice: number, currencyCode: string, searchCorrelationId: string, user: User, carrierCode: string, success?: boolean, statusCode?: number) {
  const navCarrierData: NavCarrierData = {
    carrierCode: carrierCode
  };
  if (searchCorrelationId) {
    navCarrierData.searchCorrelationID = searchCorrelationId;
  }

  const eventData: BookAction = {
    loadNumber: loadNumber,
    bookPrice: bookPrice,
    currencyCode: currencyCode
  };

  if (bookActionType == 'BookSubmitted') {
    eventData.success = success;

    if (statusCode) {
      eventData.statusCode = statusCode;
    }
  }

  await publishEvent(bookActionType, navCarrierData, user.userId.toString(), user.email, eventData);
}

export async function publishOfferActionEvent(offerActionType: OfferActionType, loadNumber: string, searchCorrelationId: string, user: User, carrierCode: string, offerPrice?: number, currencyCode?: string) {
  const navCarrierData: NavCarrierData = {
    carrierCode: carrierCode
  };
  if (searchCorrelationId) {
    navCarrierData.searchCorrelationID = searchCorrelationId;
  }
  const eventData: OfferAction = {
    loadNumber: loadNumber,
    offerPrice: offerPrice,
    currencyCode: currencyCode
  };

  await publishEvent(offerActionType, navCarrierData, user.userId.toString(), user.email, eventData);
}

function NumberNormalize(num) {
  if (num == "") {
    return null;
  }
  return num ? Number(num) : num;
}

function StringNormalize(str) {
  return str == "" ? null : str;
}

export default {
  publishLoginEvent,
  publishSearchSubmittedEvent,
  publishLoadClickedEvent
};
