import { BaseModel } from '../base.model';
import { CashAdvanceOptionsSourceV3 } from './cash-advance-options-source-V3.model';
import { CashAdvanceOptionsValidation } from './cash-advance-options-validation.model';

export class CashAdvanceOptionsV3 extends BaseModel {
  constructor(json?: CashAdvanceOptionsV3JSON) {
    super(json);

    if (json) {
      this.advanceSourceTypes = json.advanceSourceTypes.map(s => new CashAdvanceOptionsSourceV3(s));
    }
  }

  availableBalance: number;
  sourceCurrencyCode: string;
  advanceSourceTypes: CashAdvanceOptionsSourceV3[];
  carrierIsEnabled: boolean;
  carrierDisabledReason?: string;
  loadIsEnabled: boolean;
  loadDisabledReason?: string;
  promotionId?: number;
  validationErrors: CashAdvanceOptionsValidation[];
}
