import { generateQueryString } from './query-string.provider';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { validSearchCriteriaPropertyKeys } from 'pages/find-loads/available-loads-search.criteria';
import { AvailableLoadLocationType } from 'shared/enums/available-loads/location-type.enum';
import qs from 'qs';
import { Container } from 'typedi';

interface StatesByCountryListString {
  [countryCode: string]: string;
}

const LocationTypePrefixMap = {
  [AvailableLoadLocationType.ORIGIN]: 'origin',
  [AvailableLoadLocationType.DESTINATION]: 'destination'
};

interface AvailableLoadsSearchQueryParams {
  originLatitude?: number;
  originLongitude?: number;
  originCountryCode?: string;
  originStateProvinceCode?: string;
  originCity?: string;
  originStateCodes?: string;
  originStateCodesByCountryCode?: StatesByCountryListString;
  originRadiusMiles?: number;
  destinationLatitude?: number;
  destinationLongitude?: number;
  destinationCountryCode?: string;
  destinationStateProvinceCode?: string;
  destinationCity?: string;
  destinationStateCodes?: string;
  destinationStateCodesByCountryCode?: StatesByCountryListString;
  destinationRadiusMiles?: number;
  pickupStart?: string;
  pickupEnd?: string;
  milesMin?: number;
  milesMax?: number;
  weightMin?: number;
  weightMax?: number;
  mode?: string;
  isPartial?: boolean;
  specializedEquipmentCode?: string;
  teamLoad?: string;
  hazmatLoad?: string;
  carrierTierAvailable?: string;
  stfLoad?: string;
  equipmentLengthMax?: number;
  reloadsCriteria?: ReloadsSearchCriteriaJSON;
  isStfBookable?: boolean;
  webExclusive?: string;
  originExampleZipCode?: string;
  destinationExampleZipCode?: string;
}

const fetchCity = async (countryCode, stateCode, cityName): Promise<CityResponse> => {
  const repo = Container.get(ReferenceDataRepository);

  return repo.getCountryByCode(countryCode)
    .mergeMap(country =>
      repo.getStateByCode(country.id, stateCode) // grab state code
        .map(state => [country, state]) // pass country and state code to getCities call to grab country/state ids and geolocation (along with other data)
    )
    .mergeMap(([country, state]) =>
      repo.getCities(country?.id, state?.id, cityName)
    )
    .map((results) => {
      let city = results.find(result => result?.name?.toLowerCase() === cityName.toLowerCase());
      if (!city) { // if there isn't city name, look at city common name and grab the one that matches
        city = results.find(result => result?.commonName?.toLowerCase() === cityName.toLowerCase());
      }
      if (!city) { // if there isn't a city name or common name available, just grab the first result of the response.
        city = results[0];
      }
      return city;
    })
    .toPromise();
};

export const getLocation = async (countryCode: string, stateCode: string, cityQuery: string) => {
  const city = await fetchCity(countryCode, stateCode, cityQuery);

  return {
    city: city && (city.name || city.commonName),
    county: null,
    stateProvinceCode: stateCode,
    countryCode: countryCode,
    geolocation: {
      latitude: city?.latitude,
      longitude: city?.longitude
    } as GeoLocationJSON
  };
};

export async function generateAvailableLoadsQueryString(criteria: AvailableLoadSearchCriteriaJSON): Promise<string> {
  return generateQueryString(await generateAvailableLoadQueryParams(criteria));
}

export function generateAvailableLoadsReloadsQueryString(criteria: AvailableLoadSearchCriteriaJSON): string {
  let params = generateAvailableLoadReloadsQueryParams(criteria);
  return generateQueryString(params);
}

export function parseAvailableLoadsQueryString(queryString: string, isABTesting?: boolean, isCarrierCodeOdd?: boolean): AvailableLoadSearchCriteriaJSON {
  const queryParams = queryString ? qs.parse(queryString, { ignoreQueryPrefix: true, arrayLimit: Infinity }) : null;
  let searchCriteria = queryParams ? parseAvailableLoadsQueryParams(queryParams) : null;
  if (searchCriteria) {
    // Remove any search criteria that is not valid.
    Object.keys(searchCriteria).forEach(paramKey => {
      if (!validSearchCriteriaPropertyKeys.includes(paramKey)) {
        delete searchCriteria[paramKey];
      }
    })

    if (Object.keys(searchCriteria)?.length === 0) {
      // If no search criteria parameters were parsed from the url, set the search criteria to null
      searchCriteria = null;
    } else {
      // Add hardcoded search criteria (not from url query parameters)
      searchCriteria.pageSize = isABTesting ? 250 : 5000;
    }
  }

  return searchCriteria;
}

function generateAvailableLoadReloadsQueryParams(criteria: AvailableLoadSearchCriteriaJSON): AvailableLoadsSearchQueryParams {
  let response: AvailableLoadsSearchQueryParams;
  if (criteria.reloadsCriteria) {
    response = { reloadsCriteria: criteria.reloadsCriteria };
    Object.keys(response).forEach(([key, value]) => {
      if (value == null || value == '') {
        delete response[key];
      }
    });
    return response;
  }
  return null;
}

async function generateAvailableLoadQueryParams(criteria: AvailableLoadSearchCriteriaJSON): Promise<AvailableLoadsSearchQueryParams> {
  let response: AvailableLoadsSearchQueryParams = generateAvailableLoadReloadsQueryParams(criteria);
  // if there is reloads criteria, it is not necessary to process the rest of the criteria
  if (response) {
    return response;
  }
  response = { ...criteria as any };

  // process origin states
  if (criteria.originStateCodesByCountryCode) {
    response.originStateCodesByCountryCode = serializeStateCodesByCountry(criteria, AvailableLoadLocationType.ORIGIN);
    delete response.originStateCodes;
  } else if (criteria.originStateCodes) {
    response.originStateCodes = criteria.originStateCodes.join('.');
    delete response.originStateCodesByCountryCode;
  } else if (criteria.originStateProvinceCode) {
    if (!criteria.originLatitude || !criteria.originLongitude) {
      const loc = await getLocation(response.originCountryCode, response.originStateProvinceCode, response.originCity);
      response.originLatitude = loc?.geolocation?.latitude;
      response.originLongitude = loc?.geolocation?.longitude;
    }
  }

  // process destination states
  if (criteria.destinationStateCodesByCountryCode) {
    response.destinationStateCodesByCountryCode = serializeStateCodesByCountry(criteria, AvailableLoadLocationType.DESTINATION);
    delete response.destinationStateCodes;
  } else if (criteria.destinationStateCodes) {
    response.destinationStateCodes = criteria.destinationStateCodes.join('.');
    delete response.destinationStateCodesByCountryCode;
  } else if (criteria.destinationStateProvinceCode) {
    if (!criteria.destinationLatitude || !criteria.destinationLongitude) {
      const loc = await getLocation(response.destinationCountryCode, response.destinationStateProvinceCode, response.destinationCity);
      response.destinationLatitude = loc?.geolocation?.latitude;
      response.destinationLongitude = loc?.geolocation?.longitude;
    }
  }

  Object.keys(response).forEach(([key, value]) => { if (value == null || value == '') { delete response[key]; } });

  return response;
}

function convertToNumber(value: unknown): number {
  return value ? Number(value) : value as number;
}

function convertToNullableBoolean(value: unknown): boolean | null {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return null;
}

function parseAvailableLoadsQueryParams(params: AvailableLoadsSearchQueryParams): AvailableLoadSearchCriteriaJSON {
  const criteria: AvailableLoadSearchCriteriaJSON = {
    ...params as any,
  };

  // process origin states
  if (params.originStateCodesByCountryCode) {
    // when we have state codes by country, translate that property
    criteria.originStateCodesByCountryCode = deserializeStateCodesByCountryCode(params, AvailableLoadLocationType.ORIGIN);
    // but we must ALWAYS include originStateCodes (required by API when ByCountryCode is required)
    criteria.originStateCodes = (criteria.originStateCodesByCountryCode || []).reduce((codes, entry) => [...codes, ...entry.value], []);

  } else if (params.originStateCodes?.length) {
    criteria.originStateCodes = params.originStateCodes.split('.');
    delete params.originStateCodesByCountryCode;
  }

  // process destination states
  if (params.destinationStateCodesByCountryCode) {
    criteria.destinationStateCodesByCountryCode = deserializeStateCodesByCountryCode(params, AvailableLoadLocationType.DESTINATION);
    // but we must ALWAYS include destinationStateCodes (required by API when ByCountryCode is required)
    criteria.destinationStateCodes = (criteria.destinationStateCodesByCountryCode || []).reduce((codes, entry) => [...codes, ...entry.value], []);
  } else if (params.destinationStateCodes?.length) {
    criteria.destinationStateCodes = params.destinationStateCodes.split('.');
    delete params.destinationStateCodesByCountryCode;
  }

  // fix up those numbers
  if (params.originRadiusMiles) {
    criteria.originRadiusMiles = Number(params.originRadiusMiles);
  }
  if (params.destinationRadiusMiles) {
    criteria.destinationRadiusMiles = Number(params.destinationRadiusMiles);
  }

  if (params.originLatitude) {
    criteria.originLatitude = Number(params.originLatitude);
  }
  if (params.originLongitude) {
    criteria.originLongitude = Number(params.originLongitude);
  }

  if (params.destinationLatitude) {
    criteria.destinationLatitude = Number(params.destinationLatitude);
  }
  if (params.destinationLongitude) {
    criteria.destinationLongitude = Number(params.destinationLongitude);
  }

  //.teamLoad = 'test'
  if (params.teamLoad) {
    criteria.teamLoad = convertToNullableBoolean(params.teamLoad);
  }
  if (params.hazmatLoad) {
    criteria.hazmatLoad = convertToNullableBoolean(params.hazmatLoad);
  }
  if (params.carrierTierAvailable) {
    criteria.carrierTierAvailable = convertToNullableBoolean(params.carrierTierAvailable);
  }
  if (params.stfLoad && params.stfLoad === 'false') {
    criteria.stfLoad = false;
  }
  if (params.webExclusive) {
    criteria.webExclusive = convertToNullableBoolean(params.webExclusive);
  }

  if (params.reloadsCriteria) {
    params.reloadsCriteria.selectedReloadLoadNumber = convertToNumber(params.reloadsCriteria.selectedReloadLoadNumber);
    params.reloadsCriteria.searchedLoadNumber = convertToNumber(params.reloadsCriteria.searchedLoadNumber);
    params.reloadsCriteria.selectedLoadNumber = convertToNumber(params.reloadsCriteria.selectedLoadNumber);

    if (params.reloadsCriteria.primalLoadDetails) {
      params.reloadsCriteria.primalLoadDetails.primalLoadNumber =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.primalLoadNumber);

      params.reloadsCriteria.primalLoadDetails.distance =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.distance);

      params.reloadsCriteria.primalLoadDetails.deadheadMilesToOrigin =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.deadheadMilesToOrigin);

      params.reloadsCriteria.primalLoadDetails.origin.latitude =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.origin.latitude);

      params.reloadsCriteria.primalLoadDetails.origin.longitude =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.origin.longitude);

      params.reloadsCriteria.primalLoadDetails.destination.latitude =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.destination.latitude);

      params.reloadsCriteria.primalLoadDetails.destination.longitude =
        convertToNumber(params.reloadsCriteria.primalLoadDetails.destination.longitude);

      params.reloadsCriteria.primalLoadDetails.stops =
        [...(!!params.reloadsCriteria.primalLoadDetails.stops?.length &&
          params.reloadsCriteria.primalLoadDetails.stops.map((stop) => {
            return {
              ...stop,
              appointmentType: convertToNumber(stop.appointmentType),
              sequenceNumber: convertToNumber(stop.sequenceNumber),
              stopNumber: convertToNumber(stop.stopNumber),
              stopType: convertToNumber(stop.stopType)
            };
          }))];
    }
  }
  return criteria;
}

function serializeStateCodesByCountry(criteria: AvailableLoadSearchCriteriaJSON, type: AvailableLoadLocationType) {
  const key = LocationTypePrefixMap[type] + 'StateCodesByCountryCode';
  const sourceStateCodes = criteria[key];
  let stateCodes = {};

  if (sourceStateCodes) {
    stateCodes = sourceStateCodes.reduce((result, entry) => ({
      ...result,
      [entry.key]: entry.value.join('.')
    }), stateCodes);
  }

  return stateCodes;
}

function deserializeStateCodesByCountryCode(params: AvailableLoadsSearchQueryParams, type: AvailableLoadLocationType) {
  const key = LocationTypePrefixMap[type] + 'StateCodesByCountryCode';
  const sourceStateCodes = params[key];

  const stateCodesByCountryCode = [];

  if (sourceStateCodes) {
    Object.entries(sourceStateCodes as StatesByCountryListString).forEach(([countryCode, stateList]) => {
      if (stateList) {
        stateCodesByCountryCode.push(
          { key: countryCode, value: stateList.split('.') }
        );
      }
    });
  }

  // don't inject these arrays if they're empty
  if (!stateCodesByCountryCode.length) {
    return;
  }

  return stateCodesByCountryCode;
}
