import { Action } from 'redux';
import { Cookie } from 'ng2-cookies';
import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';

import * as a from 'features/security/auth.actions';
import { NavCarrierEpic } from '../nav-carrier-epic.interface';

export const setUserDataInAnalytics: NavCarrierEpic<never> = action$ =>
  action$.ofType<a.StoreCredentialsAction>(a.STORE_CREDENTIALS)
    .filter(({ carrier }) => Boolean(carrier?.getContinentCode()))
    .do(({ carrier }) => Cookie.set('region', carrier.getContinentCode(), null, '/'))
    .ignoreElements();

export const cleanupAnalyticsOnLogoutEpic: NavCarrierEpic<never> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .map(() => Cookie.delete('region'))
    .ignoreElements();

export const analyticsEpics = combineEpics(
  setUserDataInAnalytics,
  cleanupAnalyticsOnLogoutEpic,
);
