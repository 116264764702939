import { Action, AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { NavCarrierEpic } from '../../nav-carrier-epic.interface';
import { UPDATE_CARRIER_FOR_USER, updateCarrierForUserFailure } from './carrier.actions';
import { saveCarrier } from 'features/security/auth.actions';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';

type CarrierEpic<OutputAction extends Action> = NavCarrierEpic<OutputAction, {
  carriersRepo: CarriersRepository;
}>;

// TODO: remove post UM release
export const updateCarrierDetailsEpic: CarrierEpic<AnyAction> = (action$, state$, { carriersRepo }) =>
	action$.ofType(UPDATE_CARRIER_FOR_USER)
		.filter(() => state$.value.auth.isAuthenticated)
		.mergeMap(() =>
			from(carriersRepo.getCarrier(state$.value.auth?.carrier?.carrierCode))
				.map(carrierDetailJSON => {
				  const rollups = state$.value.auth?.user?.carrierRollups || []; // grab rollups from user for legacy carrier updates because it doesn't exist on carrier details
				  const divisions = state$.value.auth?.user?.divisions || []; // same for divisions
				  carrierDetailJSON.rollups = rollups;
				  carrierDetailJSON.divisions = divisions;
				  const carrierDetails = new CarrierDetail(carrierDetailJSON);
                  return saveCarrier(carrierDetails);
                })
				.catch((res: APIErrorResponse) => Observable.of(updateCarrierForUserFailure(res)))
		);

export const carrierEpics = (action$, state$) => combineEpics(
  updateCarrierDetailsEpic
)(action$, state$, {
  carriersRepo: Container.get(CarriersRepository)
});
